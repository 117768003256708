import React, { useEffect, useState } from "react";
import { IonPage } from "@ionic/react";
import Services from "../../components/MyServicePage/services/services";
import BottomPanel from "../../components/Panels/BottomPanelComponent";
import { getCompleted } from "../../functions/InformationFunctions";
import { useSelector } from "react-redux";

const MyService: React.FC = () => {
  const [tabs, setTabs] = useState(true);
  const [services, setServices]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sucesssItems, setSucessItems] = useState([]);
  const [progressItems, setProgressItems] = useState([]);
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);
  const handleTabs = () => setTabs(!tabs);

  useEffect(() => {
    if (services) {
      setSucessItems(
        services.filter(
          (item) =>
            item.request_service.state_id === 3 ||
            item.request_service.state_id === 4
        )
      );
      setProgressItems(
        services.filter(
          (item) =>
            item.request_service.state_id !== 3 &&
            item.request_service.state_id !== 4
        )
      );
    }
  }, [services]);

  const filterServices = (stateId) => {
    return services.filter((item) => item.request_service.state_id === stateId);
  };

  useEffect(() => {
    getCompleted()
      .then((res: any) => {
        if (res.data.success.length !==0) {
          setLoading(false);
          setServices(res.data.success);
        }      
      })
      .catch((res) => {
        setError(true)
        setLoading(false);
      });
  }, [setServices, getCompleted]);
  console.warn(services);

  return (
    <IonPage className="bg-gray-100 ">
      <div className="shadow-md bg-white inset-x-0 top-0 h-16 flex justify-evenly flex-row items-center ">
        <span
          onClick={handleTabs}
          className={`cursor-pointer text-lg font-bold ${
            tabs ? "text-gray-800" : "text-gray-500"
          }`}
        >
          En curso
        </span>
        <div className="border-fuchsia-600 text-gray-500">|</div>
        <span
          className={`cursor-pointer text-lg font-bold ${
            !tabs ? "text-gray-800" : "text-gray-500"
          }`}
          onClick={handleTabs}
        >
          Historial
        </span>
      </div>
      {tabs ? (
        <div className="flex mt-2 flex-1 flex-row justify-center items-center ">
           <Services key={1} service={progressItems} />
        </div>
      ) : 
         <Services key={2} service={sucesssItems} />
      }
      <BottomPanel />
    </IonPage>
  );
};

export default MyService;
