import React, { useEffect } from "react";
import "./BottomPanelComponent.css";
import { Link } from "react-router-dom";
import { sendEvent } from "../../functions/GAFunctions";
import home_logo from "../../img/home_menu.svg";
import home_logo_inactive from "../../img/homeoff.svg";
import perfil from "../../img/perfil.svg";
import perfil_inactive from "../../img/perfiloff.svg";
import productos from "../../img/productos.svg";
import productos_inactive from "../../img/productosoff.svg";
import agendamientos from "../../img/agendamientos.svg";
import agendamientos_inactive from "../../img/agendamientosoff.svg";
import { useSelector } from "react-redux";
import { addRedirect } from "../../store/actions/systemActions";
import store from "../../store";


const BottomPanel: React.FC = () => {
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);

  useEffect(() => {
    console.log('is logged bottom panel?',  st_loggedIn )
  },[]);

  const handleUrl=(url)=>{
    console.log(url)
    store.dispatch(addRedirect(url));
  }
   

  const getClassActive = (param) => {
    
    if (window.location.pathname === param) {
      return "focus:underline-none focus:text-yellow-500 hover:text-yellow-500 hover:underline-none text-yellow-500";
    }
    return "focus:underline-none focus:text-gray-700 hover:text-gray-700 hover:underline-none text-gray-700";
  };
  const getActive = (param) => {
    return window.location.pathname === param;
  };
  return (
    <div className="bottom-panel flex">
      <div className="text-center w-1/4 py-3">
        <Link
          to="/"
          className={ getClassActive("/")}
          onClick={() => sendEvent("Inicio_menu_inferior")}
        >
          <div className="item">
            <img
              src={getActive("/") ? home_logo : home_logo_inactive}
              className="mx-auto"
              alt=""
            />
            Inicio
          </div>
        </Link>
      </div>
      <div className="text-center w-1/4 py-4">
        <Link
          to={`${st_loggedIn?"/tienda":"/login"}`}
          className={getClassActive("/tienda")}
          onClick={() => sendEvent("Tienda_menu_inferior")}
        >
          <div className="item">
            <img
              src={getActive("/tienda") ? productos : productos_inactive}
              className="mx-auto"
              alt=""
            />
            Tienda
          </div>
        </Link>
      </div>
      <div className="text-center w-1/4 py-4">
        <Link
          to="/historial"
          className={getClassActive("/historial")}
          onClick={() => {return sendEvent("Historial_menu_inferior"),handleUrl('/historial')}}
        >
          <div className="item">
            <img
              src={
                getActive("/historial") ? agendamientos : agendamientos_inactive
              }
              className="mx-auto"
              alt=""
            />
            Mis Servicios
          </div>
        </Link>
      </div>
      <div className="text-center w-1/4 py-4">
        <Link
          to={`${st_loggedIn?"/profile":"/login"}`}
          className={getClassActive("/profile")}
          onClick={() =>{ return sendEvent("Perfil_menu_inferior"),handleUrl('/profile')}}
        >
          <div className="item">
            <img
              src={getActive("/profile") ? perfil : perfil_inactive}
              className="mx-auto"
              alt=""
            />
            Perfil
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BottomPanel;
