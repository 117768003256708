const initialState = {
  currentServices: [],
  currentProducts: [],
  notSame: false,
  finalPrice: 0,
  authCart:false,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      // Check if the currentCart have the service that will be included
      let currentCart = state.currentServices.filter(function (obj) {
        return obj.id === action.payload.id;
      });
      // If the currentCart have the service, add the new quantity
      if (currentCart.length > 0) {
        let newQuantity =
          parseInt(
            state.currentServices.find((x) => x.id == action.payload.id)
              .quantity
          ) + parseInt(action.payload.quantity);
        // Make an auxiliar cart withouth te item to add with the new quantity
        let newCart = state.currentServices.filter(function (obj) {
          return obj.id !== action.payload.id;
        });
        // Make an object with the intent data and with the new quantity
        let newObject = action.payload;
        newObject.quantity = newQuantity;
        // Save in localStorage  the new cart
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify([...newCart, newObject]));
        // Return the state with the new cart adding the new object that has the new quantity
        return {
          ...state,
          currentServices: [...newCart, newObject],
        };
      }

      // If the current cart doesnt have the intent item, this return the cart with the old current services merge the new service
      localStorage.removeItem("cart");
      localStorage.setItem(
        "cart",
        JSON.stringify([...state.currentServices, action.payload])
      );
      // Return the merged state with the new object
      return {
        ...state,
        currentServices: [...state.currentServices, action.payload],
      };
    case "SAVE_CART":
      return {
        ...state,
        currentServices: action.payload,
      };
      case "SET_AUTH_CART":
        return {
          ...state,
          authCart: action.payload,
        };
    case "DELETE_OF_CART":
      let newCart = state.currentServices.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      localStorage.removeItem("cart");
      if (newCart.length > 0) {
        localStorage.setItem("cart", JSON.stringify(newCart));
      }
      return {
        ...state,
        currentServices: newCart,
      };
    case "DELETE_ONE_OF_CART":
      // make an auxiliar cart to find the service
      let newCartExcept = state.currentServices;
      let index = newCartExcept.findIndex((e) => {
        return e.id === action.payload.id;
      });
      if (newCartExcept[index].quantity === 1) {
        // Filter the new cart without the service to delete if the quantity its equals to 1
        newCartExcept = state.currentServices.filter(function (obj) {
          return obj.id !== action.payload.id;
        });
        // Save in the local storage
        localStorage.removeItem("cart");
        if (newCartExcept.length > 0) {
          localStorage.setItem("cart", newCartExcept);
        }
        // return the new current services without item
        return {
          ...state,
          currentServices: newCartExcept,
        };
      }
      // if the quantity isn't equals to 1, then only delete one quantity of this item
      newCartExcept[
        newCartExcept.findIndex((e) => {
          return e.id === action.payload.id;
        })
      ].quantity--;
      localStorage.removeItem("cart");
      if (newCartExcept.length > 0) {
        localStorage.setItem("cart", JSON.stringify(newCartExcept));
      }
      let auxCart = [...newCartExcept];
      return {
        ...state,
        currentServices: auxCart,
      };
    case "DELETE_CART":
      localStorage.removeItem("cart");
      localStorage.removeItem("product-cart");
      return {
        ...state,
        currentServices: [],
        currentProducts: [],
        finalPrice: 0,
      };
    case "RESET_NOT_SAME":
      return {
        ...state,
        notSame: false,
      };
    case "SET_PRICE":
        return {
          ...state,
          finalPrice: action.payload,
        };

    case "ADD_PRODUCT_TO_CART":
      // Check if the product exist in the current cart
      let currentProductsCart = state.currentProducts.filter(function (obj) {
        return obj.id === action.payload.id;
      });
      if (currentProductsCart.length > 0) {
        let newProductQuantity =
          parseInt(currentProductsCart[0].quantity) +
          parseInt(action.payload.quantity);
        // Make an auxiliar cart withouth te item to add with the new quantity
        let newProductCart = state.currentProducts.filter(function (obj) {
          return obj.id !== action.payload.id;
        });
        // Make an object with the intent data and with the new quantity
        let newProduct = action.payload;
        newProduct.quantity = newProductQuantity;
        // Save in localStorage  the new cart
        localStorage.removeItem("product-cart");
        localStorage.setItem(
          "product-cart",
          JSON.stringify([...newProductCart, newProduct])
        );
        // Return the state with the new cart adding the new object that has the new quantity
        return {
          ...state,
          currentProducts: [...newProductCart, newProduct],
        };
      }

      // if doesnt exist, then return merged cart with the new item
      localStorage.removeItem("product-cart");
      localStorage.setItem(
        "product-cart",
        JSON.stringify([...state.currentProducts, action.payload])
      );
      // Return the merged state with the new object
      return {
        ...state,
        currentProducts: [...state.currentProducts, action.payload],
      };

    case "SAVE_PRODUCT_CART":
      return {
        ...state,
        currentProducts: action.payload,
      };
    case "DELETE_PRODUCT_OF_CART":
      let newProductCart = state.currentProducts.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      localStorage.removeItem("product-cart");
      if (newProductCart.length > 0) {
        localStorage.setItem("product-cart", JSON.stringify(newProductCart));
      }
      return {
        ...state,
        currentProducts: newProductCart,
      };
    case "DELETE_ONE_PRODUCT_OF_CART":
      // make an auxiliar cart to find the service
      let newProductCartExcept = state.currentProducts;

      let indexProduct = newProductCartExcept.findIndex((e) => {
        return e.id === action.payload.id;
      });

      if (newProductCartExcept[indexProduct].quantity === 1) {
        // Filter the new cart without the service to delete if the quantity its equals to 1
        newProductCartExcept = state.currentProducts.filter(function (obj) {
          return obj.id !== action.payload.id;
        });
        // Save in the local storage
        localStorage.removeItem("product-cart");
        if (newProductCartExcept.length > 0) {
          localStorage.setItem("product-cart", newProductCartExcept);
        }
        // return the new current services without item
        return {
          ...state,
          currentProducts: newProductCartExcept,
        };
      }

      // if the quantity isn't equals to 1, then only delete one quantity of this item
      newProductCartExcept[
        newProductCartExcept.findIndex((e) => {
          return e.id === action.payload.id;
        })
      ].quantity--;
      localStorage.removeItem("product-cart");
      if (newProductCartExcept.length > 0) {
        localStorage.setItem(
          "product-cart",
          JSON.stringify(newProductCartExcept)
        );
      }
      return {
        ...state,
        currentProducts: newProductCartExcept,
      };
    case "DELETE_PRODUCT_CART":
      localStorage.removeItem("product-cart");
      return {
        ...state,
        currentProducts: [],
      };

    default:
      break;
  }
  return state;
};

export default reducer;
