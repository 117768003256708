const initialState = {
  loading: false,
  listCategories: [],
  listSubcategories: [],
  categoryName: "",
  categoryImage: "",
  listServices: [],
  subCategoryName: "",
  subCategoryImage: "",
  serviceName: "",
  serviceImage: "",
  currentServices: [],
  currentInformation: {
    date: "",
    type: "",
    payment: "",
  },
  sentService: [],
  linkedCart: false,

  cartPage: false,
  loginPage: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_OFF":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_CATEGORIES":
      return {
        ...state,
        listCategories: action.payload,
      };
    case "SELECT_CATEGORY":
      if (
        state.listCategories.find((x) => x.id == action.payload.value) == null
      ) {
        // localStorage.removeItem("cart");
        // localStorage.removeItem("product-cart");
        return { ...state, listServices: [] };
      }
      return {
        ...state,
        listSubcategories: state.listCategories.find(
          (x) => x.id == action.payload.value
        ).subcategories,
        categoryName: state.listCategories.find(
          (x) => x.id == action.payload.value
        ).name,
        categoryImage: state.listCategories.find(
          (x) => x.id == action.payload.value
        ).image,
      };
    case "SELECT_SUBCATEGORY":
      return {
        ...state,
        listServices: state.listSubcategories.find(
          (x) => x.id == action.payload.value
        ).services,
        subCategoryName: state.listSubcategories.find(
          (x) => x.id == action.payload.value
        ).name,
        subCategoryImage: state.listSubcategories.find(
          (x) => x.id == action.payload.value
        ).image,
      };

    case "SET_CART_REDIRECT": {
      return {
        ...state,
        linkedCart: true,
      };
    }
    case "OFF_LINK": {
      return {
        ...state,
        linkedCart: false,
      };
    }
    case "SET_SERVICE":
      return {
        ...state,
        sentService: [action.payload],
      };

    case "CLEAR_STORE":
      return {
        ...state,
        sentService: [],
      };
    case "CART_PAGE":
      return {
        ...state,
        cartPage: true,
      };
    case "CART_PAGE_OFF":
      return {
        ...state,
        cartPage: false,
      };
    case "LOGIN_PAGE":
      return {
        ...state,
        cartPage: true,
      };
    case "LOGIN_PAGE_OFF":
      return {
        ...state,
        cartPage: false,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
