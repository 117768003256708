import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthenticatedMiddleware: React.FC<any> = (props: any) => {
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);

  if (st_loggedIn ===props.logged) {
   
    return (
      <Route exact path={props.path}>
        <Redirect to={props.redirect} />
      </Route>
    );
  }
  return <Route exact path={props.path} component={props.component} />;
};

export default AuthenticatedMiddleware;
