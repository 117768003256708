import React from "react";

interface tag {
  name: string;
}
const Tags: React.FC<any> = ({ tags }) => {
  return (
    <>
      <div className="overflow-x-auto flex py-6 px-6 bg-white">
        {tags.map((index, id) => {
          return (
            <div
              className="py-3 px-6 border-2 text-base font-semibold rounded-lg text-gray-500 mr-4 w-auto whitespace-no-wrap "
              key={id}
            >
              {index.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Tags;
