import React  from "react";
import { IonCardContent } from "@ionic/react";
import Message from "../../../../img/Icons/Mensaje.svg";
import MessageGray from "../../../../img/Icons/MensajeGray.svg";
import { setMessages } from "../../../../store/actions/messagesActions";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  data: string;
  icon: any;
  message?: boolean;
  messageCount: any;
  idService:any;
}

const CardItem = ({ title, icon, data, message, messageCount,idService }: Props) => {
  return (
    <IonCardContent>
      <div className=" flex flex-row flex-1 items-center justify-between  ">
        <div className="flex items-center">
          <img
            className="flex text-xl h-8 mr-3 relative float-left object-left text-yellow_1000"
            src={icon}
          />
          <div className="flex flex-col  justify-between">
            <span className="text-base text-black font-bold text-yellow_1000">
              {title}
            </span>
            <span className="text-xl text-gray-600 font-medium">{data}</span>
          </div>
        </div>
        {message === true ? (
          messageCount.length< 1 ? (
          <Link to={`/chat/${idService}`}>
            <div className="technical-icon-gray">
              <img className="flex h-6  " src={MessageGray} />
            </div>
            </Link>
          ) : (
            <Link to={`/chat/${idService}`}>
              <div className="technical-icon shadow-md bg-gradient-to-l from-yellow_1000 to-yellow-400">
                <img className="flex h-8  " src={Message} />
                <span className="bg-white absolute rounded-xl border-0 shadow-2xl border-gray-900 h-4 w-4 ml-4 mt-3 "></span>
              </div>
            </Link>
          )
        ) : null}
      </div>
    </IonCardContent>
  );
};

export default CardItem;
