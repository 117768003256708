import React from "react";
//@ts-ignore
import { Switch, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/DashboardPage";
import Login from "../../pages/Login/LoginPage";
import Register from "../../pages/Register/RegisterPage";
import RegisterManual from "../../pages/Register/RegisterManualPage";
import Suggestions from "../../pages/Suggestions/SuggestionsPage";
import RouteMiddleware from "../../components/RouteMiddleware/AuthenticatedMiddleware";
import "./HomeStyles.css";
import Category from "../Category/CategoryPage";
import Cart from "../../pages/Cart/CartPage";
import Schedule from "../../pages/Schedule/SchedulePage";
import Shop from "../Shop/ShopPage";
import Test from "../Test/Test";
import History from "../History/History";
import Chat from "../Chat/Chat";
import Profile from "../Profile/Profile";
import PaymentPage from "../PaymentPage/PaymentPage";
import LoginServicePage from "../LoginServicePage/LoginServicePage";
import ContinueWithoutRegister from "../ContinueWithoutRegister/ContinueWithoutRegister";
import { useSelector } from "react-redux";


const Home: React.FC = () => {

  const st_auth_cart: any = useSelector((state) => state.cart.authCart);

  return (
    <>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <RouteMiddleware path="/login" component={Login} logged={true} redirect={`${st_auth_cart && st_auth_cart==true?"/agendar":"/"}`}/>
        <Route exact path="/servicioLogin" component={LoginServicePage} />
        <Route exact path="/sinRegistro" component={ContinueWithoutRegister} />
{/*          <Route exact path="/creandoServicio" component={CreatingService} />
 */}        <Route navbar={false} exact path="/register" component={Register} />
        <Route exact path="/register-manual" component={RegisterManual} />
        <Route exact path="/no-encuentro" component={Suggestions} />
        <Route exact path="/categoria/:identifier" component={Category} />
        <Route exact path="/tienda" component={Shop} />
        <Route exact path="/carrito" component={Cart} />
        <Route exact path="/agendar" component={Schedule} />
        <Route exact path="/detalle/:idService" component={Test} />
        <Route exact path="/chat/:idService" component={Chat} />
        <Route exact path="/profile" component={Profile} />
        <RouteMiddleware path="/historial" component={History} logged={false} redirect="/login" />   
      </Switch>
    </>
  );
};

export default Home;
