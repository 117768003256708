import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import folderSVG from "../../img/folder.svg";
import facebookSVG from "../../img/facebook.svg";
import googleSVG from "../../img/google.svg";
//@ts-ignore
import { Link } from "react-router-dom";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//@ts-ignore
import GoogleLogin from "react-google-login";

const RegisterPage: React.FC = () => {
  const onFacebookLogin = () => {
    console.log("test");
  };
  return (
    <IonPage>
      <IonContent className="bg-auth section-padding">
        <h1 className="text-center text-3xl font-extrabold mb-16 font-body">
          Registro
        </h1>
        <Link
          to="/register-manual"
          className="text-black text-center block w-full bg-white border-2 px-6 py-4 mt-20 rounded-lg border-gray-500-force text-xl font-semibold"
        >
          <img
            src={folderSVG}
            className="w-auto float-left mt-0"
            alt="google_logo"
          />
          <p className="pt-1 mb-0">Registro manual</p>
        </Link>
        <FacebookLogin
          appId="380890929552372"
          fields="name,email,picture"
          callback={() => onFacebookLogin}
          render={(facebookProps) => (
            <button
              onClick={facebookProps.onClick}
              className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
            >
              <img
                src={facebookSVG}
                className="w-auto float-left mt-0"
                alt="facebook_logo"
              />
              <p className="pt-1 mb-0">Registro con Facebook</p>
            </button>
          )}
        />
        <GoogleLogin
          clientId="380890929552372"
          onSuccess={() => onFacebookLogin}
          render={(facebookProps) => (
            <button
              onClick={facebookProps.onClick}
              className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
            >
              <img
                src={googleSVG}
                className="w-auto float-left mt-0"
                alt="google_logo"
              />
              <p className="pt-1 mb-0">Registro con Google</p>
            </button>
          )}
        />
        <p className="text-center text-lg mt-6">
          ¡Ya tengo una cuenta! <br />
          <Link to="/login" className="font-semibold text-yellow-600">
            Ingresar
          </Link>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPage;
