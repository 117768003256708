import { IonContent, IonPage,IonModal } from "@ionic/react";
import React,{useState,forwardRef,useEffect,useMemo} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./CreatingService.css";


const CreatingService= forwardRef((props: any, ref) => {
  const st_loading: any = useSelector((state) => state.products.loading);

  return (
      <IonModal isOpen={props.visible} cssClass="forward-modal" >
          <IonContent className="bg-creatingService section-padding">
            {st_loading ===true &&  <div className="flex flex-col justify-between items-center content-spining">
                <span
                  className="text-yellow_1200 opacity-75  my-0 mx-auto block relative "
                >
                  <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                </span>
              <h1 className="text-center text-3xl font-extrabold font-body mx-10">
                Estamos creando tu servicio
              </h1>
            </div>
            }
           {/*  <Link
              onClick={()=>props.setVisible(false)}
              className=" font-semibold text-yellow_1200 cancel-service-link"
            >
              Cancelar servicio 
            </Link> */}
          </IonContent>
      </IonModal>
  );
});

export default CreatingService;
