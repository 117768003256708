import React, { useState, forwardRef } from "react";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import "react-credit-cards/es/styles-compiled.css";
import { IonContent, IonCard, IonModal, IonAlert } from "@ionic/react";
import Cards from "react-credit-cards";
import InputComponent from "../../components/Input/InputComponent";
import useForm from "react-hook-form";
import { saveCard } from "../../services/payment/paymentFunctions";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatDocument,
} from "../../common/utils/creditCard";

const PaymentPage = forwardRef((props: any, ref) => {
  const [showAlert2, setShowAlert2] = useState(false);
  const onSubmitPayment = (values) => {
    if (Object.keys(values).length !== 0) {
      let year, month;
      year = "20" + values.expiry.slice(-2);
      month = values.expiry.slice(0, 2);
      console.log("mes", month);
      let creditCard = {
        ccv: values.ccv,
        installments: values.installments,
        month,
        name: values.name,
        number: values.number,
        year,
      };
      saveCard(creditCard).then(() => props.setVisible(false));
    } else {
      setShowAlert2(true);
    }
  };
  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = useState({
    ccv: "",
    expiry: "",
    name: "",
    number: "",
    focus: "",
    installments: "",
  });

  const handleInputFocus = (e) => {
    setData({ ...data, focus: e.target.name });
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "ccv") {
      target.value = formatCVC(target.value);
    } else if (target.name === "document") {
      target.value = formatDocument(target.value);
    }
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const types = [
    { id: "1", name: "CC" },
    { id: "2", name: "CE" },
    { id: "3", name: "PA" },
  ];

  const { name, ccv, number, expiry, focus } = data;

  return (
    <IonModal isOpen={props.visible}>
      <IonAlert
        isOpen={showAlert2}
        onDidDismiss={() => setShowAlert2(false)}
        cssClass="my-custom-class"
        header={"!Error¡"}
        message={"Por favor diligencie completamente los datos de su tarjeta"}
        buttons={["Cancelar", "Llenar"]}
      />
      <IonContent className=" flex w-full h-16 flex-col  ">
        <div
          className="py-2 bg-white shadow-lg fixed w-full"
          style={{ zIndex: 200 }}
        >
          <div className="flex">
            <div className="">
              <button
                className="ml-4 pt-6 px-4"
                onClick={() => props.setVisible(false)}
              >
                <i className="fa fa-arrow-left text-4xl text-yellow-500"></i>
              </button>
            </div>
            <h2 className="mt-0 pt-6 ml-4 text-3xl font-semibold">
              Seleccionar método de pago
            </h2>
          </div>
        </div>
        <form className="h-full" onSubmit={handleSubmit(onSubmitPayment)}>
          <div className="pb-32">
            <div className=" hideElementOnKeyboardShown flex justify-center items-center pt-32 ">
              {/*     <img
              className="w-full pt-16 mx-6 focus:outline-none bg-white"
              alt="logo"
              src={credit}
            />  */}
              <Cards
                cvc={ccv}
                expiry={expiry}
                name={name}
                number={number}
                focused={focus}
              />
            </div>

            <IonCard className="flex h-auto flex-col  border rounded-lg p-3 bg-white shadow-lg px-5 ">
              <InputComponent
                type="tel"
                name="number"
                onChange={handleInputChange}
                onRef={register({ required: true })}
                label={"Número de la tarjeta"}
                placeholder={"Ej. 0000 0000 0000 0000"}
                icon={"faCircle"}
                onFocus={handleInputFocus}
                className="my-5"
                error={errors.number}
                errorMsg={"Por favor ingrese una tarjeta válida."}
              />
              <InputComponent
                type="tel"
                name="name"
                onChange={handleInputChange}
                onRef={register({ required: true })}
                label={"Nombre y apellido"}
                placeholder={"Ej. Javier Galindo "}
                icon={"faCircle"}
                className="my-5"
                onFocus={handleInputFocus}
                error={errors.name}
                errorMsg={"Por favor ingrese un nombre valido."}
              />
              <div className="flex justify-between flex-row w-full my-2">
                <InputComponent
                  onRef={register({ required: true })}
                  onChange={handleInputChange}
                  name="expiry"
                  label={"Fecha de expiración"}
                  onFocus={handleInputFocus}
                  placeholder={"Ej. 12/02"}
                  icon={"faCircle"}
                  className="flex-1 mr-3 "
                  type="tel"
                  error={errors.expiry}
                  errorMsg={"Por favor ingrese la fecha de expiración."}
                />
                <InputComponent
                  onRef={register({ required: true })}
                  onChange={handleInputChange}
                  name="ccv"
                  label={"Codigo  de Seguridad"}
                  onFocus={handleInputFocus}
                  placeholder={"Ej. 0000"}
                  icon={"faCircle"}
                  className="flex-1"
                  type="tel"
                  error={errors.cvc}
                  errorMsg={"Por favor ingrese el codigo."}
                />
              </div>
              <div className="flex justify-between flex-row w-full my-5">
                <InputComponent
                  select
                  aux={false}
                  setAux={() => false}
                  options={types}
                  onRef={register({ required: true })}
                  name="documentType"
                  label={"Tipo de documento"}
                  onFocus={handleInputFocus}
                  placeholder={"CC."}
                  icon={"faCircle"}
                  className="flex-1 mr-3 w-full "
                  type="text"
                  error={errors.documentType}
                  errorMsg={"Por favor seleccione el tipo de documento."}
                />
                <InputComponent
                  onRef={register({ required: true })}
                  onChange={handleInputChange}
                  name="document"
                  label={"Número de documento"}
                  onFocus={handleInputFocus}
                  placeholder={"000000000"}
                  icon={"faCircle"}
                  className="flex-1 w-full "
                  type="tel"
                  error={errors.document}
                  errorMsg={"Por favor ingrese el número de documento."}
                />
              </div>
              <InputComponent
                onRef={register({ required: true })}
                onChange={handleInputChange}
                name="installments"
                label={"Número de cuotas"}
                onFocus={handleInputFocus}
                placeholder={"0"}
                icon={"faCircle"}
                className="flex-1 w-full "
                type="tel"
                error={errors.document}
                errorMsg={"Ej:1"}
              />
            </IonCard>
            <button
              type="submit"
              className="fixed w-full bg-white pt-6 pb-4 px-6 border-t-2"
              style={{ zIndex: 2000000, bottom: 0 }}
            >
              <div className="boton-whapp text-center text-white py-4 font-bold rounded-lg">
                Agregar tarjeta
              </div>
            </button>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
});

export default PaymentPage;
