import React from "react";
import "./GridCategoryStyles.css";
import { Link } from "react-router-dom";
import { selectCategory } from "../../store/actions/informationActions";
import { useDispatch } from "react-redux";

type GridCategoryProps = {
  categories: Array<Object>;
};

const GridCategory: React.FC<GridCategoryProps> = (categories) => {
  const dispatch = useDispatch();
  const dispatcher = {
    setCategory: (index) => dispatch(selectCategory(index)),
  };
  const changeCategory = (category) => {
    dispatcher.setCategory(category);
  };
  const getPadding: any = (index) => {
    switch (index % 3) {
      case 1:
        return "px-3";
      case 2:
        return "pl-6";
    }
    return "pr-6";
  };
  return (
    <div>
      {categories.categories.map((item: any, index) => {
        return (
          <Link
            onClick={() => changeCategory(item.id)}
            to={"/categoria/" + item.id}
            key={index}
          >
            <div className={"w-1/3 inline-grid " + getPadding(index)}>
              <div className="shadow-lg rounded-xl bg-white text-center pb-6">
                <img src={item.image} alt="" />
              </div>
              <p className={"my-4 text-center text-xl no-underline text-black"}>
                {item.name}
              </p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default GridCategory;
