import { IonModal } from "@ionic/react";
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, deleteOneOfCart } from "../../store/actions/cartActions";
import AddQuantity from "../Button/AddQuantityComponent";
import WhyHomePartner from "../Informatives/WhyHomePartnerComponent";
import ReactHtmlParser from "react-html-parser";
import BottomCart from "../Panels/BottomCartComponent";

const ModalServiceComponent = forwardRef((props: any, ref) => {
  const st_currentServices: any = useSelector(
    (state) => state.cart.currentServices
  );
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    if (st_currentServices.length === 0) {
      setQuantity(0);
    } else {
      st_currentServices.map((x) => {
        if (x.id === props.service.id) {
          setQuantity(x.quantity);
        } else {
          setQuantity(0);
        }
      });
    }
  });
  const dispatch = useDispatch();
  const dispatcher = {
    addToCart: (value) => dispatch(addToCart(value)),
    deleteOneOfCart: (value) => dispatch(deleteOneOfCart(value)),
  };
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  const onAdd = () => {
    setQuantity(quantity + 1);
    dispatcher.addToCart({
      id: props.service.id,
      name: props.service.name,
      base_cost: props.service.base_cost,
      description: props.service.description,
      quantity: 1,
      image: props.service.image,
      category: props.service.category,
      subcategory: props.service.subcategory,
      includes: props.service.includes,
      not_include: props.service.not_include,
    });
  };

  const onMinus = () => {
    if (quantity !== 0) {
      setQuantity(quantity - 1);
      dispatcher.deleteOneOfCart(props.service.id);
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  return (
    <IonModal isOpen={showModal}>
      <div className="overflow-y-scroll">
        <div
          className="absolute z-20 bg-gray-200 p-3 text-3xl rounded-lg right-0 font-bold mt-3 mr-3"
          onClick={() => setShowModal(false)}
        >
          X
        </div>
        <img src={props.service.image} alt="" />
        <div className="px-6">
          <h3 className="font-bold text-4xl">{props.service.name}</h3>
          <p className="text-3xl">
            {formatter.format(props.service.base_cost)}
          </p>
          <div>{ReactHtmlParser(props.service.description)}</div>
          <div className="flex mt-6">
            <div className="w-5/12">
              <AddQuantity
                onAdd={onAdd}
                onMinus={onMinus}
                quantity={quantity}
              />
            </div>
            <div className="w-11/12"></div>
          </div>
        </div>
        <div className="border-t-2 my-6"></div>
        <div className="p-6">
          <div className="bg-gray-200 p-6 rounded-lg">
            <h5 className="font-bold text-2xl">¿Qué incluye el servicio?</h5>
            <div>{ReactHtmlParser(props.service.pop_includes)}</div>
            <h5 className="mt-6 font-bold text-2xl">
              ¿Qué no incluye el servicio?
            </h5>
            <div>{ReactHtmlParser(props.service.pop_not_include)}</div>
          </div>
          <div className="bg-gray-200 p-6 rounded-lg my-6">
            <p>
              *Recuerda que el valor de este servicio es una cotización
              aproximada. El costo real será confirmado por el técnico durante
              la visita.
            </p>
          </div>
          <WhyHomePartner />
        </div>
      </div>
      <BottomCart />
    </IonModal>
  );
});

export default ModalServiceComponent;
