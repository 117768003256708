  


export function loginPage() {
  return { type: "LOGIN_PAGE", };
}

export function loginOn() {
  return { type: "LOGIN" };
}

export function loginOff() {
  return { type: "LOGIN_PAGE_OFF" };
}

export function loadUser(payload, services) {
  return {
    type: "LOAD_USER",
    payload: payload,
    services: services,
  };
}

export function setAddresses(payload) {
  return {
    type: "SET_ADDRESSES",
    payload: payload,
  };
}

export function deleteAddress(payload) {
  return { type: "DELETE_ADDRESS", payload: payload };
}

export function logout() {
  return { type: "LOGOUT" };
}
