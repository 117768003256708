import { IonModal } from "@ionic/react";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./ModalStyles.css";
import { Button } from "antd";
import InputComponent from "../Input/InputComponent";

const ModalFormPassword= forwardRef((props: any, ref) => {
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  return (
    <IonModal isOpen={props.visible} cssClass="modal-custom ">
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8 flex flex-col justify-between items-center"
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}
        >
          <div className="flex flex-row w-full justify-between">
            <label className="text-3xl text-black  flex-1">
            Cambiar mi contraseña
            </label>
            <label className="text-4xl text-black font-medium  mr-5" onClick={() => props.setVisible(false)}>
              x
            </label >
            
          </div>

          <InputComponent placeholder="Contraseña" name="password" type="password" label="Contraseña" className="w-full my-5" labelColor="text-yellow_1000" icon=""/>
          <InputComponent placeholder="Contraseña" name="password2" type="password" label="Confirmar contraseña" className="w-full my-5" labelColor="text-yellow_1000" icon=""/>

          <Button
            onClick={() => props.setVisible(false)}
            className="w-full h-16  bg-green_1000 rounded-md text-white shadow-sm font-bold mb-16"
          >
            Cambiar  mi contraseña
          </Button>
        </div>
      </div>
    </IonModal>
  );
});

export default ModalFormPassword;
