import React, { useState, useEffect } from "react";
import { IonPage, IonIcon, IonToolbar, IonContent } from "@ionic/react";
import { cameraOutline } from "ionicons/icons";
import { Input } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import MessageGray from "../../img/Icons/MensajeGray.svg";
import { setMessages } from "../../store/actions/messagesActions";
import { getRequest } from "../../functions/InformationFunctions";

import "./Chat.css";
import NavbarBack from "../../components/Navbar/NavbarBack";

const Chat: React.FC = () => {
  const { idService } = useParams();
  const st_userData = useSelector((state) => state.user.userData);
  const st_messages = useSelector((state) => state.messages.messages);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [item, setItem] = useState({
    content_service: null,
    datetime: null,
    address: null,
    notes: null,
    final_price: null,
    messages: [],
  });

  useEffect(() => {
    if (item.content_service === null) {
      getRequest(idService).then((data: any) => {
        if (data.data?.success) {
          setItem(data);
          dispatch(setMessages(data.data.data.messages));
        } else {
          setRedirect(true);
        }
      });
    }
  });

  return (
    <IonPage className="bg-gray_100">
      <NavbarBack text={st_messages?.map((item) => item.author)} link="/" />
      <IonContent className="bg-white ">
        <div className="mt-32">
          {st_messages?.length < 1 ? (
            <div className=" flex flex-col justify-center items-center h-full">
              <img className="h-10 font-medium " src={MessageGray} />
              <p className="text-gray-400 mx-24 mt-4 text-center font-medium text-2xl">
                Aún no tienes mensajes ¿Deseas decirle algo?
              </p>
            </div>
          ) : (
            st_messages?.map((item: any) => (
              <>
                {item.id === st_userData?.id ? (
                  <div className="m-4 flex flex-col items-start">
                    <div className="rounded-r-l bg-gray_1000 py-8 px-3 h-auto w-3/5 text-black text-2xl tracking-wide leading-4">
                      {item.message}
                    </div>
                  </div>
                ) : (
                  <div className="m-4 flex flex-col items-end">
                    <div className="rounded-r-r bg-gray_1100 py-8 px-3 h-auto  w-3/5 text-white text-2xl tracking-wide leading-4 ">
                      {item.message}
                    </div>
                  </div>
                )}
              </>
            ))
          )}
        </div>
      </IonContent>
      <IonToolbar className="bottom-0 shadow-2xl p-2 ">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center  h-16 border-item border-gray-200 rounded px-2  flex-1 ">
            <Input
              className="bg-transparent flex-1 text-gray-400 text-base text-left w-auto h-full focus:outline-none mx-2 "
              placeholder=" Escriba un mensaje"
            />
            <span className="text-gray-400 text-base font-bold relative right-0  ">
              Enviar
            </span>
          </div>
          <IonIcon icon={cameraOutline} className=" text-black mx-4 text-6xl" />
        </div>
      </IonToolbar>
    </IonPage>
  );
};

export default Chat;
