import React, { useEffect, useState } from "react";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import { IonContent, IonPage, IonCard } from "@ionic/react";
import edit from "../../img/Icons/Editar.svg";
import mail from "../../img/Icons/Mail.svg";
import location from "../../img/Icons/Location.svg";
import celular from "../../img/Icons/celular.svg";
import lockOn from "../../img/Icons/LockOn.svg";
import logo from "../../img/Normal.svg";
import profile from "../../img/profile.svg";

import CardItem from "../../components/MyServicePage/services/CardService/CardItem";
import "./Profile.css";
import ModalFormEdit from "../../components/Popups/ModalFormEdit";
import ModalFormDirection from "../../components/Popups/ModalFormDirection";
import ModalFormPassword from "../../components/Popups/ModalFormPassword";
import BottomPanel from "../../components/Panels/BottomPanelComponent";
import { useSelector } from "react-redux";

const Profile: React.FC = () => {
  const st_user: any = useSelector((state) => state.user);
  const [visible, setvisible] = useState(false);
  const [showDirection, setshowDirection] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const handleEdit = () => setvisible(true);
  const HandleDirection = () => setshowDirection(true);
  const HandlePassword = () => setshowPassword(true);
  return (
    <IonPage>
      <IonContent className=" flex w-full h-16 flex-col mx-4 ">
        <div className="background-profile border-opacity-1 flex justify-center items-center">
          <img className="logo-position" alt="logo" src={logo} />
          <div className="flex justify-center items-end h-40 w-40 mt-32 rounded-xl bg-yellow_1100 shadow-xs">
         
         {  st_user?.userData.picture !== null?<img
              className="h-full w-full rounded-xl bg-cover"
              alt="user"
              src={ st_user?.userData.picture}
           
              
            />:<img
            className="h-32 w-32"
            alt="user"
            src={ profile}
         
            
          />}
            
          </div>
        </div>
        <h2 className="text-3xl font-bold text-center ">
          ¡Hola {st_user?.userData.name}!
        </h2>
        <div className="flex flex-row justify-between mx-6 ">
          <span>Mi información personal:</span>
          <img src={edit} alt="edit" onClick={handleEdit} />
        </div>
        <IonCard className="flex h-auto flex-col shadow-none border rounded-xl bg-white">
          <CardItem
            title="Correo electronico"
            icon={mail}
            data={st_user && st_user.userData.email}
            message={false}
            messageCount={0}
            idService={0}
          />
          <div className="divider" />
          <CardItem
            title="Ciudad"
            icon={location}
            data={st_user?.addresses[0]?.address}
            message={false}
            messageCount={0}
            idService={0}
          />
          <div className="divider" />
          <CardItem
            title="Celular"
            icon={celular}
            data={st_user?.userData.cellphone}
            message={false}
            messageCount={0}
            idService={0}
          />
        </IonCard>
        <span className="text-2xl text-black mx-6">Mis direcciones </span>
        {st_user?.addresses.map((item) => (
          <IonCard className="flex h-auto flex-col shadow-none border rounded-lg p-3 bg-white">
            <span className="font-semibold my-1 text-black">Casa</span>
            <span>{item.address}</span>
          </IonCard>
        ))}

        <button onClick={HandleDirection} className="flex w-full my-5">
          <div className="mx-6 flex rounded-lg border-2 border-yellow_1100 text-yellow_1100 font-bold bg-white w-full h-auto p-4 justify-center">
            Agregar otra dirección
          </div>
        </button>
        <span className="mx-6 text-2xl text-black my-2">Configuraciones </span>
        <button className="flex w-full my-5 mb-32" onClick={HandlePassword}>
          <div className="mx-6 flex flex-row rounded-lg justify-evenly border-2 font-bold bg-white w-full h-auto p-4 items-center">
            <img
              className="color-yellow_1100 text-base"
              src={lockOn}
              alt="lock"
            />
            <span className="text-black text-xl flex-1  ">
              Cambiar mi contraseña
            </span>
          </div>
        </button>
        <ModalFormEdit visible={visible} setVisible={setvisible} />
        <ModalFormDirection
          visible={showDirection}
          setVisible={setshowDirection}
        />
        <ModalFormPassword
          visible={showPassword}
          setVisible={setshowPassword}
        />
      </IonContent>
      <BottomPanel />
    </IonPage>
  );
};

export default Profile;
