import { Link } from "react-router-dom";
import React, { forwardRef } from "react";

const GrayButton = forwardRef((props: any, ref) => {
  return (
    <a
      className="flex items-center flex-1 justify-between border-2 border-gray-500  w-auto rounded-lg text-gray-500 font-bold text-base  p-3 h-12"
      href={props.redirect}
      target="_blank"
    >
      {props.text}
    </a>
  );
});

export default GrayButton;
