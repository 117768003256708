import React from "react";


const BottomSchedule: React.FC<any> = ({ active,type,text }) => {
  return active ? (
    <button type={type}
      className="fixed w-full bg-white pt-6 pb-4 px-6 border-t-2"
      style={{ zIndex: 2000000, bottom: 0 }}
    >
        <div className="boton-whapp text-center text-white py-4 font-bold rounded-lg">
          {text}
        </div>
    </button>
  ) : (
    <div
      className="fixed w-full bg-white pt-6 pb-4 px-6 border-t-2"
      style={{ zIndex: 2000000, bottom: 0 }}
    >
      <div className="boton-whapp-inactive text-center text-white py-4 font-bold rounded-lg">
          {text}
      </div>
    </div>
  );
};

export default BottomSchedule;
