import React,{useState} from "react";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonCard } from "@ionic/react";
import { Button } from "antd";
import ModalRate from "../Popups/ModalRate";

interface Props {
  technical: any;
  setVisible: any;
  item: any;
}

const Technical = ({ technical, setVisible, item }: Props) => {
  const [visible, setvisible] = useState(false)
  return (
    <IonCard className="m-5 p-5 h-auto shadow-md flex flex-col rounded-xl items-center justify-center  h-32 bg-white">
     <div className="flex flex-row container items-center">
     <img
        onClick={() => setVisible(true)}
        className="flex-none rounded-lg w-14 h-12 mr-4"
        src={technical.imageProfile}
      />
      <div className="flex-col flex flex-grow h-auto justify-between">
        <span className="text-2xl my-1 text-black font-bold">
          {technical.name}
        </span>
        <span className=" text-gray-600">{technical.function}</span>
      </div>
      <div className="technical-icon flex justify-center">
        <FontAwesomeIcon
          className="flex h-6 flex-grow text-yellow-1000"
          icon={faComment}
        />
      </div>
     </div>
      {item?.state__id !== 3 ? (
        <Button   onClick={() => setvisible(true)} className="w-full h-16  bg-gradient-to-r from-yellow_1000 to-yellow_1200 rounded-md text-white shadow-sm font-bold">
          Calificar el servicio
        </Button>
      ) : null}
      <ModalRate visible={visible} setVisible={setvisible} item={item && item}/>
    </IonCard>
  );
};

export default Technical;
