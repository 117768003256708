import { IonContent, IonIcon, IonPage } from "@ionic/react";
import React, { useState } from "react";

import NavbarComponent from "../../components/Navbar/NavbarComponent";
import "./ContinueWithoutRegister.css";
import facebookSVG from "../../img/facebook.svg";
import googleSVG from "../../img/google.svg";
import useForm from "react-hook-form";
import { loadUserData } from "../../functions/AuthFunctions";
import { useDispatch } from "react-redux";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//@ts-ignore
import GoogleLogin from "react-google-login";
import { loadingOn, loadingOff } from "../../store/actions/triggerActions";
import { loginPage, loginOff } from "../../store/actions/userActions";
import { setNewUser } from "../../store/actions/productsActions";
import { loginFacebook } from "../../functions/AuthFunctions";
import { arrowBackOutline } from "ionicons/icons";
import InputComponent from "../../components/Input/InputComponent";
import { Link,Redirect } from "react-router-dom";
import { useHistory } from "react-router";


const ContinueWithoutRegister: React.FC = () => {
  const history=useHistory();
  const [error, setError] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);

  const dispatcher = {
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
    loginOn: () => dispatch(loginPage()),
    loginOff: () => dispatch(loginOff()),
  };
  const onSubmit = (values) => {
    dispatch(setNewUser(values)) 
    setRedirect(true);
  };
  const onFacebookLogin = (data) => {
    loginFacebook(data)
      .then((res: any) => {
        if (res.success === false) {
          return setError(true);
        }
        loadUserData();
        dispatcher.loadingOFF();
        if (res.error) {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

    if (redirect === true) {
      return  <Redirect to="/agendar" />
    }
  return (
    <>
      <NavbarComponent />
      <IonContent>
        <IonPage>
          <IonContent className="bg-auth section-padding">
            <div className="flex flex-row justify-between mb-10 items-center">
              <div onClick={() => history.goBack()}>
                <IonIcon
                  icon={arrowBackOutline}
                  className="text-black text-4xl mt-4 "
                />
              </div>
              <h1 className="text-center text-3xl font-extrabold font-body flex-1">
                Antes de Finalizar
              </h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputComponent
                type="text"
                label="Tú nombre"
                placeholder="¿Cúal es tú nombre?"
                icon="fa fa-user-o"
                onRef={register({ required: true })}
                name="name"
                error={errors.name}
                className="mb-10"
                onFocus={() => setError(false)}
              />
              <InputComponent
                type="email"
                label="Ingresa tú correo electrónico "
                placeholder="Tú correo electrónico"
                icon="fa fa-envelope-o"
                className="mb-4"
                onRef={register({ required: true })}
                name="email"
                error={errors.email}
                onFocus={() => setError(false)}
              />
              <button
              style={{backgroundColor:'#7de58d'}}
                className={`block w-full rounded-lg text-white font-semibold text-2xl py-4 mt-6`}
              >
                Solicitar Servicio
              </button>
            </form>

            <FacebookLogin
              appId="380890929552372"
              fields="name,email,picture"
              cookies={false}
              disableMobileRedirect={true}
              callback={(e) => onFacebookLogin(e)}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={facebookSVG}
                    className="w-auto float-left mt-0"
                    alt="facebook_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Facebook</p>
                </button>
              )}
            />
            <GoogleLogin
              clientId="380890929552372"
              onSuccess={() => console.log("test")}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={googleSVG}
                    className="w-auto float-left mt-0"
                    alt="google_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Google</p>
                </button>
              )}
            />
          </IonContent>
        </IonPage>
      </IonContent>
    </>
  );
};

export default ContinueWithoutRegister;
