import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";
import InputComponent from "../../components/Input/InputComponent";
import InputSelectComponent from "../../components/Input/InputSelectComponent";
import InputTextareaComponent from "../../components/Input/InputTextArea";
import NavbarBack from "../../components/Navbar/NavbarBack";
import useForm from "react-hook-form";
import { Link } from "react-router-dom";
import { sendSuggestion } from "../../functions/SendingFunctions";

const SuggestionPage: React.FC = () => {
  const { register, handleSubmit, errors } = useForm();
  const [modo, setModo] = useState("WHATSAPP");
  const [success, setSuccess]: any = useState(false);

  const sendForm = (values) => {
    sendSuggestion(values).then(setSuccess(true));
  };

  return (
    <>
      <IonPage>
        <IonContent>
          <NavbarBack text="No encuentro el servicio" />
          <div className="px-6 py-40 bg-gray-100 min-h-screen">
            {!success ? (
              <>
                <p className="font-semibold">
                  Sabemos que hay servicios muy especificos. <br />
                  Diligencia los siguientes datos y te ayudaremos.
                </p>
                <form onSubmit={handleSubmit((data) => sendForm(data))}>
                  <input
                    type="hidden"
                    name="contact_type"
                    value={modo}
                    ref={register({ required: true })}
                  />
                  <div className="bg-white px-4 py-8 rounded-lg border mt-10">
                    <p className="font-semibold text-2xl">
                      Mi información personal
                    </p>
                    <InputComponent
                      name="name"
                      label="Nombre"
                      placeholder="Ingresa tú nombre"
                      icon=""
                      className=""
                      type="text"
                      onRef={register({ required: true })}
                      error={errors.name}
                      errorMsg="Debe ingresar un nombre válido"
                    />

                    <InputComponent
                      label="Correo Electrónico"
                      placeholder="Ingresa tú correo electrónico"
                      icon=""
                      className="mt-6"
                      type="email"
                      onRef={register({ required: true })}
                      error={errors.email}
                      name="email"
                      errorMsg="Debe ingresar un correo electrónico"
                    />
                    <InputComponent
                      label="Celular de contacto"
                      placeholder="Tú número celular"
                      icon=""
                      className="mt-6"
                      type="number"
                      name="contact"
                      onRef={register({ required: true })}
                      errorMsg="Debe ingresar el número celular"
                      error={errors.contact}
                    />
                  </div>
                  <div className="bg-white px-4 py-8 rounded-lg border mt-10">
                    <p className="font-semibold text-2xl">
                      Categoría y tipo de servicio
                    </p>
                    <InputSelectComponent
                      label="Categoría del servicio"
                      placeholder="Electricidad, plomería, etc.."
                      icon=""
                      className=""
                      name="category"
                      onRef={register({ required: true })}
                      error={errors.category}
                      options={[
                        { name: "Electricidad", value: "ELECTRICIDAD" },
                        { name: "Plomería", value: "PLOMERIA" },
                        { name: "Cerrajería", value: "CERRAJERIA" },
                        { name: "Pintura", value: "PINTURA" },
                        { name: "Instalaciones", value: "INSTALACIONES" },
                        { name: "Otro", value: "OTRO" },
                      ]}
                      errorMsg="Por favor selecciona la categoría"
                    />
                    <InputTextareaComponent
                      label="¿Cuál es el servicio que no encuentras?"
                      placeholder="Describe tú problema o servicio"
                      icon=""
                      className="mt-6"
                      name="description"
                      onRef={register({ required: true })}
                      error={errors.description}
                      errorMsg="Por favor comentanos la descripción del problema"
                    />
                    <div className="w-full border-t border-gray-400 my-6"></div>
                    <p className="font-semibold text-2xl">
                      ¿Cómo deseas que te contactemos?
                    </p>
                    <div className="flex mt-6">
                      <div className="w-1/2 mr-2">
                        <div
                          className={
                            "border border-gray-500 rounded p-2 " +
                            (modo === "WHATSAPP" ? " bg-black" : null)
                          }
                          onClick={() => setModo("WHATSAPP")}
                        >
                          <p
                            className={
                              "text-base text-center mb-0 " +
                              (modo === "WHATSAPP"
                                ? "text-white"
                                : "text-gray-600")
                            }
                          >
                            <i className="fa fa-whatsapp"></i> Escribirme a
                            Whatsapp
                          </p>
                        </div>
                      </div>
                      <div className="w-1/2 mr-2">
                        <div
                          className={
                            "border border-gray-500 rounded p-2 " +
                            (modo === "TELEFONO" ? " bg-black" : null)
                          }
                          onClick={() => setModo("TELEFONO")}
                        >
                          <p
                            className={
                              "text-base text-center mb-0 " +
                              (modo === "TELEFONO"
                                ? "text-white"
                                : "text-gray-600")
                            }
                          >
                            <i className="fa fa-phone"></i> Llamarme a mi
                            celular
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-yellow-100 p-4 mt-6 rounded-lg">
                      <p className="text-center font-semibold">
                        En menos de 20 minutos, un especialista de Home Partner
                        se pondrá en contácto.
                      </p>
                    </div>
                  </div>
                  <button
                    className={`block w-full rounded-lg boton-whapp text-white font-bold text-2xl py-4 h-full mt-10`}
                    type="submit"
                  >
                    Encontrar mi servicio
                  </button>
                </form>
              </>
            ) : (
              <div>
                <h3 className="font-bold">Éxito</h3>
                <p>La solicitud ha sido enviada con éxito</p>
                <Link to="/">
                  <button className="btn bg-black text-white rounded-full  mt-20">
                    Regresar al inicio <i className="fa fa-arrow-left"></i>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default SuggestionPage;
