import { IonContent, IonPage, isPlatform } from "@ionic/react";
import React, { useState } from "react";
//@ts-ignore
import { Link } from "react-router-dom";
import InputComponent from "../../components/Input/InputComponent";
import NavbarComponent from "../../components/Navbar/NavbarComponent";
import "./LoginStyles.css";
import facebookSVG from "../../img/facebook.svg";
import googleSVG from "../../img/google.svg";
import { login } from "../../functions/AuthFunctions";
import { loadUserData } from "./../../functions/AuthFunctions";
import { useSelector, useDispatch } from "react-redux";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Plugins } from "@capacitor/core";
//@ts-ignore
import GoogleLogin from "react-google-login";
import { loadingOn, loadingOff } from "../../store/actions/triggerActions";
import { loginPage, loginOff, loginOn } from "../../store/actions/userActions";
import { clearRedirect } from "../../store/actions/systemActions";
import { loginFacebook } from "../../functions/AuthFunctions";
import { useHistory } from "react-router";

const LoginPage: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword]: any = useState("");
  const [error, setError] = useState(false);
  const st_loading: any = useSelector((state) => state.information.loading);
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);
  const st_cartLinked: any = useSelector(
    (state) => state.information.linkedCart
  );
  const st_loginPage: any = useSelector((state) => state.information.loginPage);
  const st_redirect: any = useSelector((state) => state.system.redirect);

  const dispatch = useDispatch();
  const dispatcher = {
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
    loginOn: () => dispatch(loginPage()),
    loginOff: () => dispatch(loginOff()),
  };

  const getCurrentState = async () => {
    const result = await Plugins.FacebookLogin.getCurrentAccessToken();
    try {
      return result && result.accessToken;
    } catch (e) {
      return false;
    }
  };

  const signInFb = async () => {
    const FACEBOOK_PERMISSIONS = ["public_profile", "email"];
    const result = await Plugins.FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });
    if (result && result.accessToken) {
      history.push({
        pathname: "/",
        state: {
          token: result.accessToken.token,
          userId: result.accessToken.userId,
        },
      });
      localStorage.setItem("key", result.accessToken.token);
      dispatch(loginOn());
    }
  };

  function onSubmit(e) {
    e.preventDefault();
    dispatcher.loadingOn();
    const user = {
      email: email,
      password: password,
    };
    login(user)
      .then((res: any) => {
        history.push(st_redirect);
        dispatch(clearRedirect());
        loadUserData();
        dispatcher.loadingOFF();
        if (res.error) {
          setError(true);
        }
      })
      .catch((err) => {
        dispatcher.loadingOFF();
        setError(true);
      });
  }

  const onFacebookLogin = (data) => {
    loginFacebook(data)
      .then((res: any) => {
        if (res.success === false) {
          return setError(true);
        }
        loadUserData();
        dispatcher.loadingOFF();
        if (res.error) {
          setError(true);
        }
      })
      .catch((err) => {
        dispatcher.loadingOFF();
        setError(true);
      });
  };

  return (
    <>
      <NavbarComponent />
      <IonContent>
        <IonPage>
          <IonContent className="bg-auth section-padding">
            <h1 className="text-center text-3xl font-extrabold mb-16 font-body">
              Iniciar Sesión
            </h1>
            {error && (
              <p className="text-center font-bold text-red-500">
                Verifica los datos de inicio de sesión
              </p>
            )}
            <form noValidate onSubmit={onSubmit}>
              <InputComponent
                type="text"
                label="Ingresa tú correo electrónico"
                placeholder="Tú correo electrónico"
                icon="fa fa-user-o"
                className="mb-10"
                onFocus={() => setError(false)}
                value={email}
                onChange={setEmail}
              />
              <InputComponent
                type="password"
                label="Ingresa tú contraseña"
                placeholder="Contraseña"
                icon="fa fa-lock"
                className="mb-4"
                onFocus={() => setError(false)}
                value={password}
                onChange={setPassword}
              />
              <p className="text-center text-lg mt-6">
                ¿Tienes problemas para acceder? <br />
                <Link className="font-semibold text-yellow-600">
                  Recuperar mi contraseña
                </Link>
              </p>
              <button
                disabled={st_loading ? true : false}
                className={`block w-full rounded-lg degrade-amarillo text-white font-semibold text-2xl py-4 mt-6`}
              >
                {st_loading ? "Cargando ..." : "Ingresar a mi cuenta"}
              </button>
            </form>
            {/* {
              !isPlatform('mobile')?   <FacebookLogin
              appId="380890929552372"
              fields="name,email,picture"
              cookies={false}
              disableMobileRedirect={true}
              callback={(e) => onFacebookLogin(e)}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={facebookSVG}
                    className="w-auto float-left mt-0"
                    alt="facebook_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Facebook</p>
                </button>
              )}
            />:
            <button
            onClick={() => signInFb()}
            className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
          >
            <img
              src={facebookSVG}
              className="w-auto float-left mt-0"
              alt="facebook_logo"
            />
            <p className="pt-1 mb-0">Ingresa con Facebook</p>
          </button>
            } */}
            {/* <GoogleLogin
              clientId="380890929552372"
              onSuccess={() => console.log("test")}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={googleSVG}
                    className="w-auto float-left mt-0"
                    alt="google_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Google</p>
                </button>
              )}
            /> */}
            <p className="text-center text-lg mt-6">
              ¿No tienes cuenta aún? <br />
              <Link
                to="/register-manual"
                className="font-semibold text-yellow-600"
              >
                Registrarme
              </Link>
            </p>
          </IonContent>
        </IonPage>
      </IonContent>
    </>
  );
};

export default LoginPage;
