import { IonIcon } from "@ionic/react";
import { message, Upload } from "antd";
import React,{useState} from "react";

interface PropsInputImage {
    imageUrl?: string;
    setImageUrl: any;
  }

const InputImage2 = ({
    imageUrl,
    setImageUrl,
  }: PropsInputImage) => {
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);}
      function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }

    const handleChange = info => {
        console.log(info)
        if (info.file.status === 'uploading') {
          return;
        }
        getBase64(info.file.originFileObj, imageUrl =>(
          setImageUrl(imageUrl)
        )
          
        );
        if (info.file.status === 'done') {
          // Get this url from response in real world.
       
        }

        console.warn(imageUrl)
      };
      const uploadButton = (
          <>
        <div className=" rounded-xl h-40 w-40  border-4 border-dashed bg-blue-100 rounded-lg flex content-center justify-center flex-wrap"
        >
        <div className="deleteImageRounded"> 
          X
        </div>
        </div>
        <div className="border border-black text-center rounded-lg py-3 mt-6 ">
        <i className="fa fa-camera"></i> Agregar una evidencia
      </div>
      </>
      );
  return (
    <Upload
    multiple
    name="avatar"
    listType="picture-card"
    className="avatar-uploader"
    showUploadList={false}
    beforeUpload={beforeUpload}
    onChange={handleChange}
  >
    {imageUrl ?
          <div className="w-40 rounded-lg flex content-center justify-center flex-wrap"
          >
            <img
            className="rounded-xl h-40 w-40"
            src={imageUrl}
            alt="avatar"
          /> 
          <div className="deleteImageRounded" onClick={()=>setImageUrl('')} > 
            X
          </div>
          </div> : uploadButton}
  </Upload>
  );
};

export default InputImage2;
