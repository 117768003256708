import React, { useState } from "react";
import { Link } from "react-router-dom";
import { setFinalPrice,setAuthCart } from "../../store/actions/cartActions";
import store from "../../store";
import { useSelector } from "react-redux";

const BottomCheckout: React.FC<any> = ({
  total,
  subTotal,
  disscount,
  productTotal,
  services,
  products,
  quantity,
}) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);


  const handleTotal = () => {
    store.dispatch(setFinalPrice(total));
    store.dispatch(setAuthCart(true));
  };
  return (
    <div
      className="fixed w-full bg-white pt-6 pb-4 px-6 border-t-2"
      style={{ zIndex: 2000000, bottom: 0 }}
    >
      <div className="flex">
        <div className="w-8/12">
          <h4 className="font-bold text-2xl">Total</h4>
          {services?.length !== 0 && (
            <p className="text-gray-600">Valor servicios</p>
          )}
          {products?.length !== 0 && (
            <p className="text-gray-600">Valor productos</p>
          )}
          {services?.length < 1 || quantity < 2 ? null : (
            <p className="text-gray-600">Descuento</p>
          )}
        </div>
        <div className="w-4/12">
          <h4 className="text-right font-bold">{formatter.format(total)}</h4>
          <p className="text-right font-bold">
            {services?.length >= 1 && formatter.format(subTotal)}
          </p>
          {products?.length >= 1 ? (
            <p className="text-right font-bold">
              {formatter.format(productTotal)}
            </p>
          ) : null}
          {services?.length < 1 || quantity < 2 ? null : (
            <>
              <p className="text-right font-bold">
                -{formatter.format(disscount)}
              </p>
            </>
          )}
        </div>
      </div>
      <Link onClick={handleTotal} to={ `${st_loggedIn && st_loggedIn?"/agendar":"/servicioLogin"}`}>
        <div className="boton-whapp text-center text-white py-4 font-bold rounded-lg">
          Agendar servicios
        </div>
      </Link>
    </div>
  );
};

export default BottomCheckout;
