import { IonModal, IonPage } from "@ionic/react";
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import "./ModalStyles.css";
import Precio from "../../img/precios.svg";
import Credit from "../../img/creditcard.svg";
import Payu from "../../img/payu.png";
import { setChash,cleanCreditCard } from "../../store/actions/paymentActions";
import { useDispatch } from "react-redux";
import { useHistory, } from "react-router-dom";
import {FaLink} from 'react-icons/fa'


const ModalPayInformation = forwardRef((props: any, ref) => {
  const dispatch = useDispatch();


  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  const setPayment = (value) => {
    props.setPayment(value);
    setShowModal(false);
    if (value ==="Efectivo") {
      dispatch(setChash(true))
      dispatch(cleanCreditCard())
      history.push("/agendar");
    }else{
      props.setVisible(true)
/*         history.push("/tarjeta"); */
    }
  };

  return (
    <IonModal isOpen={showModal} cssClass="modal-custom">
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8"
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}
        >
          <div className="flex ">
            <div className="text-3xl font-bold w-11/12">
              <p>Selecciona el metodo de pago</p>
            </div>
            <div onClick={() => setShowModal(false)}>
              <p className="font-extrabold text-2xl text-right w-1/12">X</p>
            </div>
          </div>
          <div
            className="border p-6 rounded-lg mt-6"
            onClick={() => setPayment("Efectivo")}
          >
            <h3 className="font-semibold text-2xl">
              <img src={Precio} className="float-left mr-6" alt="" /> Pago en
              efectivo
            </h3>
            <p className="mt-6 text-gray-500">
              Paga cuando el servicio este finalizado, el pago se le realiza al
              técnico
            </p>
          </div>
          <div
            className="border p-6 rounded-lg mt-6"
            onClick={() => setPayment("Tarjeta")}
          >
            <h3 className="font-semibold text-2xl">
              <img src={Credit} className="float-left mr-6" alt="" /> Pago con
              tarjeta de crédito
            </h3>
            <p className="mt-6 text-gray-500">
              Paga de forma segura con tu tarjeta de crédito a traves de PayU
              Latam
            </p>
          </div>
          <div
            className="border p-6 rounded-lg mt-6"
           /*  onClick={() => setPayment("Tarjeta")} */
          >
            <h3 className="font-semibold text-2xl">
              <FaLink className="float-left mr-6" />Pago por Link
{/*               <img src={FaLink}  /> Pago por Link
 */}            </h3>
            <p className="mt-6 text-gray-500">
              Puedes pagar por medio de un link de pago
            </p>
          </div>
          <div className="mt-6 flex">
            <span className="w-1/2"></span>
            <img src={Payu} className="w-1/2" alt="" />
          </div>
        </div>
      </div>
    </IonModal>
  );
});

export default ModalPayInformation;
