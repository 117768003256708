import { IonContent, IonDatetime, IonPage } from "@ionic/react";
import React, { useRef, useState } from "react";
import InputComponent from "../../components/Input/InputComponent";
import InputTextareaComponent from "../../components/Input/InputTextArea";
import NavbarBack from "../../components/Navbar/NavbarBack";
import { Link } from "react-router-dom";
import BottomSchedule from "../../components/Panels/BottomScheduleComponent";
import ModalPayInformation from "../../components/Popups/ModalPayInformation";
import useForm from "react-hook-form";
import { createSchedule } from "../../services/products/productFunctions";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import ModalPayPage from "../../pages/PaymentPage/PaymentPage";
import ModalCreate from "../../pages/CreatingService/CreatingService";
import ModalCreatedService from "../../components/Popups/ModalCreatedService";
import { dummy } from "../../common/utils/Dummy";
import InputImage2 from "../../components/Input/InputImage";

const Schedule: React.FC = () => {
  const [modo, setModo] = useState("INMEDIATO");
  const [visible, setvisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [aux, setAux] = useState(false);
  const st_loggedIn: any = useSelector((state) => state.user.loggedIn);
  const st_addresses: Array<Object> = useSelector(
    (state) => state.user.addresses
  );
  const st_cardCredidCard: any = useSelector((state) => state.payment);
  const st_cart: any = useSelector((state) => state.cart);
  const st_address: any = useSelector((state) => state.user.addresses);
  const st_error: any = useSelector((state) => state.products.error);
  const st_success: any = useSelector((state) => state.products.success);
  const st_newUser: any = useSelector((state) => state.products.newUser);
  const st_message: any = useSelector((state) => state.products.message);
  const [visiblePayment, setVisiblePayment] = useState(true);

  const { register, handleSubmit, errors } = useForm();
  const popUpRef = useRef<any>();
  const onSubmit = (values) => {
    let data = {};
    if (aux || st_addresses.length === 0) {
      data = {
        schedule: {
          addressNew: {
            id: 1,
            name: values.newAddressName,
            description: values.newAddressName,
          },
          ...values,
        },
        payment: st_cardCredidCard,
        cart: st_cart,
        modo: modo,
        evidence: Image,
        newUser: st_newUser && st_newUser,
      };
    } else {
      data = {
        schedule: values,
        payment: st_cardCredidCard,
        cart: st_cart,
        modo: modo,
        evidence: Image,
        newUser: st_newUser && st_newUser,
      };
    }

    if (visible == false) {
      createSchedule(data, st_addresses);
      setCreateVisible(true);
    }
  };
  const creditCard = st_cardCredidCard?.creditCard?.number.substring(
    st_cardCredidCard?.creditCard.number.length - 4
  );
  const [Image, setImage] = useState("");
  return (
    <>
      {st_cart?.currentServices.length > 0 ||
      st_cart?.currentProducts.length > 0 ? (
        <IonPage>
          <IonContent>
            <NavbarBack text="Agenda tú servicio" link="/carrito" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="px-6 mt-32 pb-32">
                <div className="border p-6 rounded-lg shadow bg-white">
                  <h3 className="text-2xl font-semibold mt-2 ">
                    Cuándo quieres recibir el servicio?
                  </h3>
                  <div className="flex mt-6">
                    <div className="w-1/2 mr-2">
                      <div
                        className={
                          "border border-gray-500 rounded p-2 " +
                          (modo === "INMEDIATO" ? " bg-black" : null)
                        }
                        onClick={() => setModo("INMEDIATO")}
                      >
                        <p
                          className={
                            "text-base text-center mb-0 " +
                            (modo === "INMEDIATO"
                              ? "text-white"
                              : "text-gray-600")
                          }
                        >
                          Inmediato
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 mr-2">
                      <div
                        className={
                          "border border-gray-500 rounded p-2 " +
                          (modo === "PROGRAMADO" ? " bg-black" : null)
                        }
                        onClick={() => setModo("PROGRAMADO")}
                      >
                        <p
                          className={
                            "text-base text-center mb-0 " +
                            (modo === "PROGRAMADO"
                              ? "text-white"
                              : "text-gray-600")
                          }
                        >
                          Programar visita
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {modo === "PROGRAMADO" ? (
                      <>
                        <h3 className="font-semibold text-2xl mt-6">
                          Escoge la fecha y hora de programación
                        </h3>

                        <IonDatetime
                          displayFormat="DD-MM-YYYY H:mm"
                          name="date"
                          ref={register}
                          className="border mt-6"
                          doneText="Aceptar"
                          placeholder="Escoge la fecha de tu servicio"
                          cancelText="Cancelar"
                          monthShortNames={[
                            "Ene",
                            "Feb",
                            "Mar",
                            "Abr",
                            "May",
                            "Jun",
                            "Jul",
                            "Ago",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dic",
                          ]}
                        />
                        <IonDatetime
                          ref={register}
                          name="hour"
                          className="border mt-6"
                          doneText="Aceptar"
                          placeholder="Escoge la hora de tu servicio"
                          cancelText="Cancelar"
                          displayFormat="h:mm A"
                          minuteValues="0,30"
                        ></IonDatetime>
                      </>
                    ) : null}
                  </div>
                  <div className="border-t my-6"></div>
                  <h3 className="font-semibold text-2xl">Domicilio</h3>

                  {st_loggedIn === true &&
                    st_addresses.length > 0 &&
                    aux !== true && (
                      <InputComponent
                        select
                        aux={aux}
                        setAux={setAux}
                        optionsName="name"
                        options={st_address && st_address}
                        onRef={register({ required: true })}
                        name="address"
                        label={"Dirección"}
                        placeholder={"CC."}
                        icon=""
                        className="flex-1 mr-3 w-full "
                        type="text"
                        error={errors.address}
                        errorMsg={"Por favor ingrese una dirección valida."}
                      />
                    )}
                     <div className="w-full mt-4">
                    <div
                      className={
                        "border border-gray-500 rounded p-2 " +
                        (aux === true && " bg-black")
                      }
                      onClick={() => setAux(!aux)}
                    >
                      <p
                        className={
                          "text-base text-center mb-0 " +
                          (aux === true ? "text-white" : "text-gray-600")
                        }
                      >
                        {aux === false ? "Agregar" : "Seleccionar"} Dirección
                      </p>
                    </div>
                     </div>
                      {(aux === true || st_addresses.length === 0) && (
                    <>
                      <InputComponent
                        name="newAddressName"
                        onRef={register({ required: true })}
                        label="Dirección"
                        placeholder="Cuál es tú dirección?"
                        icon=""
                        className="mt-6"
                        type="text"
                        error={errors.newAddressName}
                        errorMsg={"Por favor ingrese una dirección valida."}
                      />
                      <InputComponent
                        name="newAddressDescription"
                        onRef={register({ required: true })}
                        label="Información adicional del domicilio"
                        placeholder="Conjunto, torre, apartamento, etc."
                        icon=""
                        error={errors.newAddressDescription}
                        className="mt-6"
                        type="text"
                      />
                    </>
                  )}
                  

                  <InputComponent
                    name="phone"
                    onRef={register({ required: true })}
                    label="Celular de contacto"
                    placeholder="Ingresa tú número de celular"
                    icon=""
                    className="mt-6"
                    type="number"
                    error={errors.phone}
                    errorMsg={"Por favor ingrese un telefono valido."}
                  />
                  <h3 className="font-semibold text-2xl mt-6">
                    Sobre el servicio:
                  </h3>
                  <InputTextareaComponent
                    name="notes"
                    onRef={register({ required: true })}
                    label="Tienes alguna nota para el técnico?"
                    placeholder="Agregar tús notas"
                    icon=""
                    className="mt-6"
                    error={errors.notes}
                  />
                  <h3 className="font-semibold text-2xl mt-6">
                    Evidencia adjuntada (opcional)
                  </h3>
                  <InputImage2
                    imageUrl={Image}
                    setImageUrl={(e, file) => setImage(e)}
                  />
                </div>
                <div className="bg-white border rounded-lg mt-6 p-6">
                  {creditCard ? (
                    <div>
                      <span>•••• •••• •••• {creditCard} </span>
                      <div>
                        <h3 className="text-2xl font-bold">
                          Cambiar método de pago
                        </h3>
                        <div
                          className="border rounded-lg p-4"
                          onClick={() => popUpRef.current.showPopUp()}
                        >
                          <i className="fa fa-credit-card mr-4"></i> Cambiar el
                          método de pago
                        </div>
                      </div>
                    </div>
                  ) : paymentType === "" ? (
                    <div>
                      <h3 className="text-2xl font-bold">
                        Seleccionar método de pago
                      </h3>
                      <div
                        className="border rounded-lg p-4"
                        onClick={() => popUpRef.current.showPopUp()}
                      >
                        <i className="fa fa-credit-card mr-4"></i> Selecciona el
                        método de pago
                      </div>
                    </div>
                  ) : (
                    <div
                      className="border rounded-lg p-4"
                      onClick={() => popUpRef.current.showPopUp()}
                    >
                      {paymentType === "Tarjeta" ? (
                        <>
                          <i className="fa fa-credit-card mr-4"></i> Pago con
                          tarjeta de crédito.
                        </>
                      ) : (
                        <>
                          <i className="fa fa-money mr-4"></i> Pago en efectivo
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className="rounded-lg p-4 mt-6"
                    style={{ backgroundColor: "#FFF6E5" }}
                  >
                    <div className="flex">
                      <div>
                        <input
                          ref={register({ required: true })}
                          name="terms"
                          type="checkbox"
                        />
                      </div>
                      <div className="ml-4">
                        <p className="mb-0">
                          Acepto los términos y condiciones del servicio
                          <Link className="text-yellow-500">AQUÍ</Link>
                          descritos
                        </p>
                      </div>
                    </div>
                    {errors.terms && (
                      <span className="text-red-500 text-base">
                        Debe aceptar los terminos y condiciones
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <BottomSchedule text="Solicitar servicio" active={true} />
              <ModalPayInformation
                setVisible={setvisible}
                ref={popUpRef}
                paymentType={paymentType}
                setPayment={(value) => setPaymentType(value)}
              />

              <ModalPayPage setVisible={setvisible} visible={visible} />
              {(st_error == true || st_success == true) && (
                <ModalCreatedService
                  visibleError={st_error}
                  message={st_message}
                  setVisibleModal={setvisible}
                  visible={createVisible}
                  setVisible={setCreateVisible}
                  councils={dummy.councils}
                />
              )}
              <ModalCreate
                visible={createVisible}
                setVisible={setCreateVisible}
              />
            </form>
          </IonContent>
        </IonPage>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Schedule;
