import { createStore, combineReducers } from "redux";
import templatesReducer from "./reducers/templatesReducer";
import informationReducer from "./reducers/informationReducer";
import userReducer from "./reducers/userReducer";
import productReducer from "./reducers/productsReducer";
import cartReducer from "./reducers/cartReducer";
import messageReducer from "./reducers/messagesReducer";
import systemReducer from "./reducers/systemReducer";
import checkoutReducer from "./reducers/checkoutReducer";
import paymentReducer from "./reducers/paymentReducer";


const rootReducer = combineReducers({
  templates: templatesReducer,
  information: informationReducer,
  user: userReducer,
  products: productReducer,
  cart: cartReducer,
  messages: messageReducer,
  system: systemReducer,
  checkout: checkoutReducer,
  payment:paymentReducer,
});

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer /* preloadedState, */,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export default store;
