export function setProductCategories(item) {
    return {
        type: "SET_PRODUCT_CATEGORIES",
        payload: item,
    };
}

export function setProductSubcategories(item) {
    return {
        type: "SET_PRODUCT_SUBCATEGORIES",
        payload: item,
    };
}

export function setProducts(item) {
    return {
        type: "SET_PRODUCTS",
        payload: item,
    };
}

export function setProductCategoryName(item) {
    return {
        type: "SET_PRODUCT_CATEGORY_NAME",
        payload: item,
    };
}

export function setProductSubcategoryName(item) {
    return {
        type: "SET_PRODUCT_SUBCATEGORY_NAME",
        payload: item,
    };
}

export function setProduct(item) {
    return {
        type: "SET_PRODUCT",
        payload: item,
    };
}

export function setSchedule(item) {
    return {
        type: "SET_SCHEDULE",
        payload: item,
    };
}
export function setTempSchedule(item) {
    return {
        type: "SET_TEMP_SCHEDULE",
        payload: item,
    };
}

export function setNewUser(item) {
    return {
        type: "SET_NEW_USER",
        payload: item,
    };
}

export function setProductCategoryByProduct(item) {
    return {
        type: "SET_PRODUCT_CATEGORY_BY_PRODUCT",
        payload: item,
    };
}

export function setLoading(item) {
    return {
        type: "SET_LOADING",
        payload: item,
    };
}
export function setError(item) {
    return {
        type: "SET_ERROR",
        payload: item,
    };
}

export function setSuccess(item) {
    return {
        type: "SET_SUCCESS",
        payload: item,
    };
}