export function addCreditCard(item) {
  return {
    type: "ADD_CREDIT_CARD",
    payload: item,
  };
}


export function setChash(item) {
  return {
    type: "SET_CASH",
    payload: item,
  };
}

export function cleanCreditCard(item) {
  return {
    type: "CLEAN_CREDIT_CARD",
    payload: item,
  };
}
