import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";
import NavbarBack from "../../components/Navbar/NavbarBack";
import { useSelector } from "react-redux";
import GridService from "../../components/Grids/GridServiceComponent";
import ServiceSlide from "../../components/Slides/ServiceSlideComponent";
import Costs from "../../components/Informatives/CostsComponent";
import { Link } from "react-router-dom";
import GridProduct from "../../components/Grids/GridProductComponent";
import BottomCheckout from "../../components/Panels/BottomCheckoutComponent";
import { match } from "assert";
import RelatedComponent from "../../components/Informatives/RelatedComponent";

const Cart: React.FC = () => {
  const st_currentServices: any = useSelector(
    (state) => state.cart.currentServices
  );
  const st_currentProducts: any = useSelector(
    (state) => state.cart.currentProducts
  );
  const [state_discount, setDiscount] = useState(false);
  const [state_total, setTotal] = useState(0);
  const [state_quantity, setQuantity] = useState(0);

  let total = 0;
  let subTotal = 0;
  let max = 0;
  let quantity = 0;
  let disscount = 0;
  st_currentServices?.map((item) => {
    quantity = quantity + item.quantity;
    if (item.quantity > 1 && !state_discount) {
      setDiscount(true);
    }
    if (max < item.base_cost) {
      max = item.base_cost;
    }
    subTotal = subTotal + item.base_cost * item.quantity;
  });

  if (st_currentServices.length > 1 || state_discount) {
    disscount = (subTotal - max) * 0.25;
    total = (subTotal - max) * 0.75 + max;
    if (!state_discount) {
      setDiscount(true);
    }
  } else {
    total = subTotal;
  }

  let productTotal = 0;
  if (st_currentProducts.length > 0) {
    st_currentProducts.map((item) => {
      // quantity = quantity + item.quantity;
      productTotal = productTotal + item.price_base * item.quantity;
    });
  }
  if (state_total !== total + productTotal) {
    setTotal(total + productTotal);
  }
  if (state_quantity !== quantity) {
    setQuantity(quantity);
  }

  return (
    <div>
      <IonPage>
        <IonContent>
          <NavbarBack text="Detalle del  carrito " link="/" />
          <div className="px-6 mt-32 mb-56">
            <div>
              {st_currentServices.length >=1 ? (
                <div>
                  <GridService
                    subcategoryName={"Servicios"}
                    services={st_currentServices}
                  />
                  <div className="border-t-2 my-6"></div>
                  <Link to="/categoria/27" className="w-full flex justify-center text-gray-400 border-2 border-gray-400 py-3 text-center rounded-lg font-bold  ">
                    Agregar otro servicio
                  </Link>
                </div>
              ) : null}

              {st_currentProducts.length >=1 ? (
                <div>
                  <GridProduct
                    subcategoryName={"Productos"}
                    products={st_currentProducts}
                  />
                </div>
              ) : null}
              <div className="mt-8">
                {((st_currentProducts.length >= 1) || ( st_currentServices.length >=1))  ? (
                  <ServiceSlide
                    title="Podría interesarte"
                    services={st_currentServices}
                    products={st_currentProducts}
                  />
                ) : null}
              </div>
              {st_currentServices?.length >= 1 ? (
                <Costs cost={subTotal} />
              ) : null}
            </div>
            {st_currentProducts.length ===0 && st_currentServices.length === 0 && (
              <div>
                <p className="font-semibold mt-64 text-center text-gray-600">
                  <i className="fa fa-shopping-cart fa-5x"></i>
                  <br /> <br />
                  No hay nada en el carrito
                </p>
                <div className="flex mt-6">
                  <div className="w-1/4"></div>
                  <div className="w-2/4">
                    <Link to="/">
                      <div className="text-gray-600 border-2 rounded-lg text-center p-6">
                        <i className="fa fa-home"></i> Volver al inicio
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {/*             {st_currentProducts?.listProducts && <RelatedComponent related={st_currentProducts?.listProducts} /> }
             */}{" "}
          </div>
        </IonContent>
      </IonPage>
      {st_currentProducts.length + st_currentServices.length > 0 && (
        <BottomCheckout
          quantity={quantity}
          services={st_currentServices}
          products={st_currentProducts}
          productTotal={productTotal}
          disscount={disscount}
          total={total + productTotal}
          subTotal={subTotal}
        />
      )}
    </div>
  );
};

export default Cart;
