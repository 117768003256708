import React, { useEffect, useState } from "react";

import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home/HomePage";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./App.css"

/* Theme variables */

import "./tailwind.output.css";

import { loadUserData } from "./functions/AuthFunctions";
import { getSystemCore } from "./functions/InformationFunctions";
import { useSelector } from "react-redux";
import Pusher from "./services/pusher/Pusher";
import store from "./store";
import { saveCart, saveProductCart } from "./store/actions/cartActions";

import {Keyboard} from '@ionic-native/keyboard'


const App: React.FC = () => {


  if (Keyboard.isVisible) {
    document.body.classList.add('keyboard-is-open');
  }


  const { logPusher, setPusher } = Pusher;
  const [loggedIn] = useState(false);
  const st_userData = useSelector((state) => state.user.userData);
  useEffect(() => {
    getSystemCore();
    console.log("Arrancando");
    if (localStorage.getItem("key") !== null) {
      if (loggedIn !== true) {
        loadUserData().then((data) => {
          logPusher();
          setPusher("channel_" + st_userData.id);
        });
      }
    }
    // Load cart from the local storage
    try {
      if (localStorage.getItem("cart") !== null) {
        console.log("Loading Cart");

        store.dispatch(
          saveCart(JSON.parse(String(localStorage.getItem("cart"))))
        );
        console.log("Cart Loaded");
      }
    } catch (error) {
      console.log("Error Carrito");
      localStorage.removeItem("cart");
    }
    try {
      if (localStorage.getItem("product-cart") !== null) {
        store.dispatch(
          saveProductCart(
            JSON.parse(String(localStorage.getItem("product-cart")))
          )
        );
      }
    } catch (error) {
      localStorage.removeItem("product-cart");
    }
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Home />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
