import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./DashboardStyles.css";
import InputSearchBar from "../../components/Input/InputSearchBarComponent";
import WhatsappButton from "../../components/Button/WhatsappButtonComponent";
import GridCategory from "../../components/Grids/GridCategoryComponent";
import NavbarComponent from "../../components/Navbar/NavbarComponent";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Banner from "../../img/BannerDashboard.png";
import WhyHomePartner from "../../components/Informatives/WhyHomePartnerComponent";
import BottomPanel from "../../components/Panels/BottomPanelComponent";
import { loadUserData } from "../../functions/AuthFunctions";
import { loadUser } from "../../store/actions/userActions";
import { Skeleton, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DashboardPage: React.FC = () => {
  const antIcon = (
    <LoadingOutlined
      className="mx-auto"
      style={{ fontSize: 100, color: "#FCBC31" }}
      spin
    />
  );

  const st_categories: any = useSelector(
    (state) => state.information.listCategories
  );

  const st_loading: any = useSelector((state) => state.products.loading);

  const st_user: any = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [User, setUser] = useState({});
  const location = useLocation();

  const getUserInfo = async () => {
    const response = await fetch(
      `https://graph.facebook.com/${location.state?.userId}?fields=id,name,first_name,email,gender,link,picture&type=large&access_token=${location.state?.token}`
    );
    const myJson = await response.json();
    dispatch(
      loadUser({
        id: myJson.id,
        name: myJson.first_name,
        picture: myJson.picture?.data.url,
        email: myJson.email,
      })
    );
    setUser({
      user: myJson,
    });
  };

  useEffect(() => {
    getUserInfo();
    console.log(st_user && st_user);
  }, []);

  return (
    <>
      <NavbarComponent />
      <IonContent>
        <IonPage>
          <IonContent className="bg-gris padding-home ">
            <div className="flex hidden">
              <div className="w-10/12 pr-3">
                <InputSearchBar />
              </div>

              <div className="w-2/12 pl-2">
                <button
                  className={`block w-full rounded-lg degrade-amarillo text-white font-semibold text-2xl py-4 h-full`}
                >
                  <i className="fa fa-bell text-4xl"></i>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <img src={Banner} className="min-w-full" alt="" />
            </div>
            <div className="mt-6">
              <WhatsappButton />
            </div>
            <div className="mt-10">
              <h3 className="font-bold text-3xl">Servicios para tu hogar</h3>
              <Spin
                className="flex"
                indicator={antIcon}
                size={"large"}
                spinning={st_loading}
              >
                <div className="mt-8">
                  <GridCategory categories={st_categories} />
                </div>
              </Spin>
            </div>
            {/* <div className="mt-6 text-center">
              <Link
                to="/"
                className="text-yellow-500 font-bold text-2xl tracking-tight"
              >
                Ver más servicios <i className="fa fa-chevron-down"></i>
              </Link>
            </div> */}
            <div className="my-8">
              <Link to="/no-encuentro">
                <button
                  className={`block w-full rounded-lg degrade-amarillo text-white font-bold text-2xl py-4 h-full`}
                >
                  No encuentro mi servicio
                </button>
              </Link>
            </div>
            <WhyHomePartner />
          </IonContent>
        </IonPage>
      </IonContent>
      <BottomPanel />
    </>
  );
};

export default DashboardPage;
