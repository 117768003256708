import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavbarSelect from "../../components/Navbar/NavbarSelect";
import { useSelector, useDispatch } from "react-redux";
import { setProductCategoryName } from "../../store/actions/productsActions";
import GridProduct from "../../components/Grids/GridProductComponent";
import BottomCart from "../../components/Panels/BottomCartComponent";

const ShopPage: React.FC = () => {
  let category = useParams();
  const [categorySelected, setCategorySelected] = useState("all");
  const st_listProductCategories = useSelector(
    (state) => state.products.listProductCategories
  );

  const dispatch = useDispatch();
  const dispatcher = {
    setCategory: (index) => dispatch(setProductCategoryName(index)),
  };

  const changeCategory = (index) => {
    if (index !== "all") {
      dispatcher.setCategory(index);
    }
    setCategorySelected(index);
  };

  useEffect(() => {
    // if (st_listProductCategories.length === 0) {
    //   dispatcher.setCategory(category.identifier);
    // }
  });

  return (
    <>
      <IonPage>
        <IonContent>
          <NavbarSelect
            select={st_listProductCategories}
            onChange={(value) => changeCategory(value)}
            selected={categorySelected}
            all="Todos los productos"
          />

          <div className="mt-32 px-6">
            {st_listProductCategories.map((index, id) => {
              if (index.subcategories.length !== 0) {
                if (categorySelected === "all") {
                  return (
                    <GridProduct
                      key={id}
                      subcategoryName={index.subcategories[0].name}
                      products={index.subcategories[0].products}
                    />
                  );
                } else {
                  console.log(categorySelected + "..." + index.id);
                  if (parseInt(categorySelected) === parseInt(index.id)) {
                    return (
                      <GridProduct
                        key={id}
                        subcategoryName={index.subcategories[0].name}
                        products={index.subcategories[0].products}
                      />
                    );
                  }
                }
              }
            })}
          </div>
        </IonContent>
      </IonPage>
      <BottomCart />
    </>
  );
};

export default ShopPage;
