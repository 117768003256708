import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteOneProductOfCart,
  addProductToCart,
} from "../../store/actions/cartActions";
import AddQuantity from "../Button/AddQuantityComponent";

const ProductIndividual: React.FC<any> = ({ product,notAdd }) => {
  const st_current_products: any = useSelector(
    (state) => state.cart.currentProducts
  );
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    st_current_products.map((x) => {
      if (x.id === product.id) {
        return setQuantity(x.quantity);
      }
      return null;
    });
  });
  const dispatch = useDispatch();
  const dispatcher = {
    addToCart: (value) => dispatch(addProductToCart(value)),
    deleteOneOfCart: (value) => dispatch(deleteOneProductOfCart(value)),
  };

  const onAdd = () => {
    setQuantity(quantity + 1);
    dispatcher.addToCart({
      id: product.id,
      name: product.name,
      price_base: product.price_base,
      quantity: 1,
      picture: product.picture,
      services:product.services
    });
  };

  const onMinus = () => {
    if (quantity !== 0) {
      setQuantity(quantity - 1);
      dispatcher.deleteOneOfCart(product);
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const popUpRef = useRef<any>();
  return (
    <div className="w-full">
      <div className="flex pt-6">
        <div className="w-3/12 px-3">
          <div
            style={{
              backgroundImage: `url(${product.picture}`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full h-full rounded-lg"
          ></div>
        </div>
        <div className="w-9/12">
          <p className="font-bold text-3xl mb-0">{product.name}</p>
          <p className="mt-2 text-gray-700 text-2xl">
            {formatter.format(product.price_base)}
          </p>
        </div>
        <div></div>
      </div>
      <div className="flex mt-6 mb-6">
        <div className="w-10/12">
          <p
            onClick={() => popUpRef.current.showPopUp()}
            className="text-yellow-500 font-bold text-2xl mb-0"
          >
            Ver detalles <i className="fa fa-arrow-right"></i>
          </p>
        </div>
        <div className="text-right">
        {(notAdd == false) ? <AddQuantity onAdd={onAdd} onMinus={onMinus} quantity={quantity} />:null
}          </div>
      </div>
      <div className="border-t mb-6"></div>
    </div>
  );
};

export default ProductIndividual;
