import { IonModal } from "@ionic/react";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./ModalStyles.css";
import { Button } from "antd";
import InputComponent from "../Input/InputComponent";
import useForm from "react-hook-form";

const ModalFormEdit = forwardRef((props: any, ref) => {
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (values) => {
    console.log("values", values);
  };

  return (
    <IonModal isOpen={props.visible} cssClass="modal-custom ">
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8 flex flex-col justify-between items-center"
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}
        >
          <div className="flex flex-row w-full justify-between">
            <label className="text-3xl text-black  flex-1">
              Editar mi información
            </label>
            <label
              className="text-4xl text-black font-medium  mr-5"
              onClick={() => props.setVisible(false)}>
              x
            </label>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <InputComponent
              onRef={register({ required: true })}
              placeholder="correo@gmail.com"
              name="email"
              type="email"
              label="Correo electronico"
              className="w-full my-5"
              labelColor="text-yellow_1000"
              error={errors.email}
              icon=""
            />
            <InputComponent
              onRef={register({ required: true })}
              placeholder="Bogotá"
              name="city"
              type="text"
              label="Ciudad"
              className="w-full my-5"
              labelColor="text-yellow_1000 "
              error={errors.city}

              icon=""
            />
            <InputComponent
              onRef={register({ required: true })}
              placeholder="312456587"
              name="phone"
              type="text"
              label="Telefono"
              className="w-full my-5"
              labelColor="text-yellow_1000 my-5"
              error={errors.phone}

              icon=""
            />
            <Button
              htmlType="submit"
              className="w-full h-16  bg-green_1000 rounded-md text-white shadow-sm font-bold mb-16"
            >
              Guardar mi información
            </Button>
          </form>
        </div>
      </div>
    </IonModal>
  );
});

export default ModalFormEdit;
