import React from "react";
import ServiceIndividual from "../Individuals/ServiceIndividualComponent";

const GridService: React.FC<any> = ({ subcategoryName, services }) => {
  return (
    <div>
      <h3 className="font-bold my-6">
        {subcategoryName === "UNICA" ? "Servicios" : subcategoryName}
      </h3>
      <div>
        {services?.map((index, id) => {
          return (
            <ServiceIndividual notAdd={false} service={index} services={services} key={id} />
          );
        })}
      </div>
    </div>
  );
};

export default GridService;
