import React, { useState } from "react";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import Calendar from "../../../../img/Icons/Calendar.svg";
import Time from "../../../../img/Icons/Tiempo.svg";
import Electric from "../../../../img/backgrounds/electric.png";
import { IonCard, IonCardContent } from "@ionic/react";
import CardItem from "./CardItem";
interface Props {
  services?: any;
}

const CardService = ({ services }: Props) => {
  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return "Solicitado";
      case 2:
        return "Asignado";
      case 3:
        return "Finalizado";
      case 4:
        return "Cancelado";
      case 5:
        return "En curso";
      case 6:
        return "Reprogramado";
      case 7:
        return "Pendiente por finalizar";
    }
  };
  return (
    <IonCard className="flex h-auto flex-col shadow-md  rounded-xl  bg-white">
      <span className="absolute top-2 text-base text-green_1000 mr-5  right-0 font-bold">
        {renderSwitch(services?.request_service.state_id)}
      </span>
      <IonCardContent className="flex-row flex items-center flex-1 border-b-2 p-3">
        <a
          className="flex-row flex items-center "
          href={`/detalle/${services?.request_service.id}`}
        >
          <img
            className="flex-none object-cover rounded-md w-1/5 h-20 mr-3"
            src={
              services?.technician === null
                ? Electric
                : services?.technician?.picture
            }
          />
          <div className="custom-col justify-between flex-grow h-auto">
            <span className=" text-gray-800  font-bold">
              {services?.request_service?.content_service?.slice(11)}
            </span>
            <p className="text-sm text-gray-500 font-medium mb-6">
              {services.price}
            </p>
          </div>
        </a>
      </IonCardContent>
      <CardItem
        title="Programada para"
        icon={Calendar}
        data={services?.request_service.notes}
        message={false}
        messageCount={services.messageCount}
        idService={services?.request_service.id}
      />
      <div className="divider" />
      <CardItem
        title="Dirección del servicio"
        icon={Time}
        data={services?.address.address}
        message={true}
        idService={services?.request_service.id}
        messageCount={services?.request_service?.messages}
      />
    </IonCard>
  );
};

export default CardService;
