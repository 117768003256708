import axios from "axios";
import { serverlink } from "../../enviroment";
import {
  setSchedule,
  setError,
  setLoading,
  setSuccess,
} from "../../store/actions/productsActions";
import store from "../../store";
import moment from "moment";
import CryptoJS from "crypto-js";

export const createSchedule = (data, st_address) => {
  console.log("schedule", data, "ADRESS", st_address);
  let Payment = data?.payment.creditCard !== null ? "Tarjeta de credito" : "Efectivo";
  let notes = "Programado para en menos de 90 minutos | " + Payment;
  let formatDate =
    new moment(data?.schedule.date).format("YYYY-MM-DD") +
    " " +
    new moment(data?.schedule.time).format("HH:mm:ss");
  let dates = new Date();
  let session_id=document.cookie

  let sessionId = CryptoJS.MD5(session_id  + dates.getTime());
  console.log('cookie',document.cookie)
  if (data.modo !== "INMEDIATO") {
    notes = "Programado para " + formatDate + " | " + Payment;
  }

  let content_service = "SERVICIOS: \n";
  if (data?.cart.currentServices.length > 0) {
    data.cart.currentServices.map(
      (item) =>
        (content_service =
          content_service +
          " " +
          item.name +
          " x " +
          item.quantity +
          " - " +
          item.base_cost * item.quantity +
          " \n")
    );
  } else {
    content_service = "";
  }

  let content_product = "\n PRODUCTOS: \n";
  if (data?.cart.currentProducts.length > 0) {
    data.cart.currentProducts.map(
      (item) =>
        (content_product =
          content_product +
          " " +
          item.name +
          " x " +
          item.quantity +
          " - " +
          item.price_base * item.quantity +
          " \n")
    );
  } else {
    content_product = "";
  }

  let newAddress, address_id;
  if (
    data?.schedule.addressNew!=null ||
    st_address?.length === 0
  ) {
    newAddress = data?.schedule.addressNew;
    console.log("entro aqui y muestra", data?.schedule.addressNew);
  } else {
    console.log("sino entro aqui y muestra", data?.schedule.address);
    address_id = data?.schedule.address;
  }

  const newData = {
    newAddress,
    address_id,
    datetime: formatDate,
    notes: notes,
    final_price: data?.cart.finalPrice,
    content_service: content_service + content_product,
    aditional_notes: data?.schedule.notes,
    cellphone: data?.schedule.phone,
    services: data?.cart.currentServices,
    products: data?.cart.currentProducts,
    payu: data?.payment.creditCard !== null && true,
    creditCard: data?.payment.creditCard,
    evidence: data?.evidence,
    newUser: data?.newUser,
    session_id:""+ sessionId,
  };

  store.dispatch(setSchedule(newData));
  store.dispatch(setLoading(true));
  return axios
    .post(serverlink + "/api/sendRequest", newData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      console.log("myresp", response);
      if (response.data.success !== "false") {
        store.dispatch(setLoading(false));
        store.dispatch(setSuccess(true));
        store.dispatch(setSchedule(response.data));
      } else {
        store.dispatch(
          setError({ error: true, message: response.data.message })
        );
        store.dispatch(setLoading(false));
      }
      return {
        success: true,
      };
    })
    .catch((err) => {
      store.dispatch(setLoading(false));
      store.dispatch(setError({ error: true, message: "" }));
      return { error: true };
    });
};
