import { IonSlide, IonSlides } from "@ionic/react";
import React, { useState } from "react";
import ServiceIndividual from "../Individuals/ServiceIndividualComponent";
import ProductIndividual from "../Individuals/ProductIndividualComponent";
import "./ServiceSlideStyles.css";
import Slider from "react-slick";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const ServiceSlide: React.FC<any> = ({ services, title, mode, products }) => {
  console.log("servici", services);

  console.log("producto", products);

  const compareRealted = (id) => {
    let filterVar;
    filterVar = services.filter((e) => e.id === id);
    return filterVar.length === 0;
  };

  const compareProductRealted = (id) => {
    let filterVar;
    filterVar = products.filter((e) => e.id === id);
    return filterVar.length === 0;
  };

  var settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const itemServices: any = [];
  const itemProducts: any = [];

  if (services.length >= 1) {
    services?.map((index) => {
      if (index.products) {
        index?.products.map((item, index) => {
          if (compareProductRealted(item.id)) {
            itemProducts.push(item);
          }
        });
      }
    });
  }

  if (products.length >= 1) {
    products?.map((index) => {
      if (index.services) {
        index?.services.map((i, id) => {
          if (compareRealted(i.id)) {
            itemServices.push(i);
          }
        });
      }
    });
  }

  return (
    <div>
      {(itemProducts.length >= 1 || itemServices.length >= 1) && (
        <h4 className="font-bold">{title}</h4>
      )}
      <Carousel showIndicators={true} showStatus={false}>
        {itemServices.map((item, index) => (
          <ServiceIndividual notAdd={false} service={item} key={index} />
        ))}
        {itemProducts.map((item, index) => (
          <div className="block">
            <ProductIndividual notAdd={false} product={item} key={index} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ServiceSlide;
