const initialState = {
  creditCard: null,
  cash:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CREDIT_CARD":
      return { ...state, creditCard: action.payload };
      case "SET_CASH":
      return { ...state, cash: action.payload };

    case "CLEAN_CREDIT_CARD":
      return { ...state, creditCard: null };
    default:
      return state;
  }
};

export default reducer;
