import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import InputComponent from "../../components/Input/InputComponent";
//@ts-ignore
import { Link, Redirect } from "react-router-dom";
import Swal from "sweetalert2-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadingOn, loadingOff } from "../../store/actions/triggerActions";
import { register } from "../../functions/AuthFunctions";

const RegisterManualPage: React.FC = () => {
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setError(true);
      setMsgError("Las contraseñas no coinciden.");
      return;
    }
    dispatch(loadingOn());
    const dataSend = {
      name: name,
      last_name: last_name,
      email: email,
      cellphone: cellphone,
      password: password,
      password2: password2,
    };

    register(dataSend)
      .then((res: any) => {
        if (res.success) {
          setSuccess(true);
        } else {
          setError(true);
          setMsgError(res.msg);
        }
      })
      .catch((err) => {
        setError(true);
        setMsgError(err.data);
      });
  };
  return (
    <IonPage>
      <IonContent className="bg-auth section-padding">
        <h1 className="text-center text-3xl font-extrabold mb-16 font-body">
          Registro Manual
        </h1>
        <form onSubmit={onSubmit}>
          <InputComponent
            type="text"
            label="Ingresa tú nombre"
            placeholder="Tú nombre"
            icon="fa fa-user-o fa-fw"
            className="mb-10"
            value={name}
            onChange={setName}
          />
          <InputComponent
            type="text"
            label="Ingresa tú apellido"
            placeholder="Tú apellido"
            icon="fa fa-user-o fa-fw"
            className="mb-10"
            value={last_name}
            onChange={setLastName}
          />
          <InputComponent
            type="text"
            label="¿Cuál es tú número celular?"
            placeholder="Número de celular"
            icon="fa fa-phone fa-fw"
            className="mb-10"
            value={cellphone}
            onChange={setCellphone}
          />
          <InputComponent
            type="text"
            label="Ingresa tú correo electrónico"
            placeholder="Tú correo electrónico"
            icon="fa fa-envelope-o fa-fw"
            className="mb-10"
            value={email}
            onChange={setEmail}
          />
          <InputComponent
            type="password"
            label="Ingresa tú contraseña"
            placeholder="Contraseña"
            icon="fa fa-lock fa-fw"
            className="mb-4"
            value={password}
            onChange={setPassword}
          />
          <InputComponent
            type="password"
            label="Confirma tú contraseña"
            placeholder="Repite tu contraseña"
            icon="fa fa-lock fa-fw"
            className="mb-4"
            value={password2}
            onChange={setPassword2}
          />
          <p className="text-center text-lg mt-6">
            Al dar clic en Crear mi cuenta, aceptaras los <br />
            <Link to="/login" className="font-semibold text-yellow-600">
              Términos y condiciones
            </Link>{" "}
            de Home Partner
          </p>
          <button className="block w-full rounded-lg degrade-amarillo text-white font-semibold text-2xl py-4 mt-6">
            Crear mi cuenta
          </button>
        </form>
        <Swal
          show={error}
          title="Error"
          text={msgError}
          icon="error"
          onConfirm={() => setError(false)}
        ></Swal>
        <Swal
          show={success}
          title="Éxito"
          text="El usuario ha sido creado con éxito, igresa a la plataforma con tús datos"
          icon="success"
          onConfirm={() => setRedirect(true)}
        ></Swal>
        {redirect && <Redirect to="/login"></Redirect>}
      </IonContent>
    </IonPage>
  );
};

export default RegisterManualPage;
