import axios from 'axios'
import { serverlink } from "../../enviroment";
import { addCreditCard } from "../../store/actions/paymentActions";
import store from "../../store";



export const saveCard = (data) => {
  store.dispatch(addCreditCard(data));
  return axios
    .post(serverlink + "https://jsonplaceholder.typicode.com/posts", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      store.dispatch(addCreditCard(response.data));
      return {
        success: true,
      };
    })
    .catch((err) => {
      return { error: true };
    });
};