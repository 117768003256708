import React, { useEffect, useState } from "react";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import StepsComponent from "../../components/Steps/index";
import Technical from "../../components/technical/index";
import Service from "../../components/Service/index";
import Requests from "../../components/Request/index";
import Slider from "../../components/Slider/index";
import ModalTechnical from "../../components/Popups/ModalTechnical";
import { dummy } from "../../common/utils/Dummy";
import { getRequest } from "../../functions/InformationFunctions";
import { useParams } from 'react-router';

const Test: React.FC = () => {

const [visible, setvisible] = useState(false)
const [item, setItem] = useState({
  content_service: null,
  datetime: null,
  address: null,
  notes: null,
  final_price: null,
  messages: [],
});
const { idService } = useParams();
const [redirect, setRedirect] = useState(false);

useEffect(() => {
  if (item.content_service === null) {
    getRequest(idService).then((data:any) => {
      if (data.data?.success) {
        setItem(data.data.data);
      } else {
        setRedirect(true);
      }
    });
  }
});

useEffect(() => {
  console.log('servicio',item,idService)
}, [item])

  return (
    <IonPage>
      <IonToolbar className="shadow-md">
        <IonButtons slot="start">
          <IonBackButton className="text-yellow-500" defaultHref="/historial" />
        </IonButtons>
        <label className="text-black ">Detalle del Servicio</label>
      </IonToolbar>
      <IonContent>
        {item && (
          <>
            <StepsComponent />
            <Technical setVisible={setvisible} item={item} technical={dummy.service[0].technical} />
            <Service services={item} />
            <Requests request={dummy.service[0].requestAditional} />
            <Slider popup={false} councils={dummy.councils} />
          </>
        )}
      </IonContent>
      <ModalTechnical councils={dummy.councils} setVisible={setvisible} visible={visible}/>
    </IonPage>
  );
};

export default Test;
