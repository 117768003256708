import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteOneOfCart, addToCart } from "../../store/actions/cartActions";
import AddQuantity from "../Button/AddQuantityComponent";
import ModalServiceComponent from "../Popups/ModalServiceComponent";

const ServiceIndividual: React.FC<any> = ({ service,notAdd }) => {
  
  const st_currentServices: any = useSelector(
    (state) => state.cart.currentServices
  );
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    st_currentServices.map((x) => {
      if (x.id === service.id) {
        return setQuantity(x.quantity);
      }
      return null;
    });
  });
  const dispatch = useDispatch();
  const dispatcher = {
    addToCart: (value) => dispatch(addToCart(value)),
    deleteOneOfCart: (value) => dispatch(deleteOneOfCart(value)),
  };

  const onAdd = () => {
    setQuantity(quantity + 1);
    dispatcher.addToCart({
      id: service.id,
      name: service.name,
      base_cost: service.base_cost,
      description: service.description,
      quantity: 1,
      category: service.category,
      subcategory: service.subcategory,
      includes: service.includes,
      not_include: service.not_include,
      image: service.image,
      products: service.products,
    });
  };

  const onMinus = () => {
    if (quantity !== 0) {
      setQuantity(quantity - 1);
      dispatcher.deleteOneOfCart(service.id);
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const popUpRef = useRef<any>();
  return (
    <div className="inline-grid">
      <div className="flex pt-6">
        <div className="w-3/12 px-3">
          <div
            style={{
              backgroundImage: `url(${service.image}`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full h-full rounded-lg"
          ></div>
        </div>
        <div className="w-9/12">
          <p className="font-bold text-3xl mb-0">{service.name}</p>
          <p className="mt-2 text-gray-700 text-2xl">
            {quantity > 0
              ? formatter.format(service.base_cost * quantity)
              : formatter.format(service.base_cost)}
          </p>
        </div>
        <div></div>
      </div>
      <div className="flex mt-6 mb-6">
        <div className="w-10/12">
          <p
            onClick={() => popUpRef.current.showPopUp()}
            className="text-yellow-500 font-bold text-2xl mb-0"
          >
            Ver detalles <i className="fa fa-arrow-right"></i>
          </p>
        </div>
        <div className="text-right">
        { (notAdd== false) && <AddQuantity onAdd={onAdd} onMinus={onMinus} quantity={quantity} />}
        </div>
      </div>
      <div className="border-t mb-6"></div>
      <ModalServiceComponent ref={popUpRef} service={service} />
    </div>
  );
};

export default ServiceIndividual;
