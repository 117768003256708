import { IonContent, IonIcon, IonPage } from "@ionic/react";
import React, { useState } from "react";

import NavbarComponent from "../../components/Navbar/NavbarComponent";
import "./LoginServiceStyles.css";
import facebookSVG from "../../img/facebook.svg";
import googleSVG from "../../img/google.svg";
import user from "../../img/PerfilAccount.svg";
import project from "../../img/Proyecto.svg";
import anonymus from "../../img/Anonimo.svg";
import { Link } from "react-router-dom";

import { loadUserData } from "../../functions/AuthFunctions";
import { useDispatch } from "react-redux";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//@ts-ignore
import GoogleLogin from "react-google-login";
import { loadingOn, loadingOff } from "../../store/actions/triggerActions";
import { loginPage, loginOff } from "../../store/actions/userActions";
import { loginFacebook } from "../../functions/AuthFunctions";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router";

const LoginPage: React.FC = () => {
  const [error, setError] = useState(false);
  const history=useHistory();
  const dispatch = useDispatch();
  const dispatcher = {
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
    loginOn: () => dispatch(loginPage()),
    loginOff: () => dispatch(loginOff()),
  };

  const onFacebookLogin = (data) => {
    loginFacebook(data)
      .then((res: any) => {
        if (res.success === false) {
          return setError(true);
        }
        loadUserData();
        dispatcher.loadingOFF();
        if (res.error) {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  return (
    <>
      <NavbarComponent />
      <IonContent>
        <IonPage>
          <IonContent className="bg-auth section-padding">
            <div className="flex flex-row justify-between mb-10 items-center">
              <div onClick={() => history.goBack()}>
                <IonIcon
                  icon={arrowBackOutline}
                  className="text-black text-4xl mt-4 "
                />
              </div>
              <h1 className="text-center text-3xl font-extrabold font-body flex-1">
                Antes de Finalizar
              </h1>
            </div>
            <Link to="sinRegistro">
              <button className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold">
                <img
                  src={anonymus}
                  className="w-auto float-left mt-0"
                  alt="facebook_logo"
                />
                <p className="pt-1 mb-0 text-black">Continuar sin registrarme</p>
              </button>
            </Link>
            <Link to="/login">
              <button className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold">
                <img
                  src={user}
                  className="w-auto float-left mt-0"
                  alt="facebook_logo"
                />
                <p className="pt-1 mb-0 text-black">Ingresar a mi cuenta</p>
              </button>
            </Link>
            <Link to="/register">
              <button className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold">
                <img
                  src={project}
                  className="w-auto float-left mt-0"
                  alt="facebook_logo"
                />
                <p className="pt-1 mb-0 text-black">Crear una cuenta</p>
              </button>
            </Link>

            <div className="divider my-16" />

            <FacebookLogin
              appId="380890929552372"
              fields="name,email,picture"
              cookies={false}
              disableMobileRedirect={true}
              callback={(e) => onFacebookLogin(e)}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={facebookSVG}
                    className="w-auto float-left mt-0"
                    alt="facebook_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Facebook</p>
                </button>
              )}
            />
            <GoogleLogin
              clientId="380890929552372"
              onSuccess={() => console.log("test")}
              render={(facebookProps) => (
                <button
                  onClick={facebookProps.onClick}
                  className="text-center block w-full bg-white border-2 px-6 py-4 mt-6 rounded-lg border-gray-500-force text-xl font-semibold"
                >
                  <img
                    src={googleSVG}
                    className="w-auto float-left mt-0"
                    alt="google_logo"
                  />
                  <p className="pt-1 mb-0">Ingresa con Google</p>
                </button>
              )}
            />
          </IonContent>
        </IonPage>
      </IonContent>
    </>
  );
};

export default LoginPage;
