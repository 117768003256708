import { IonModal } from "@ionic/react";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./ModalStyles.css";

import image1 from "../../img/backgrounds/electric.png";
import { Button } from "antd";
import GrayButton from "../Button/ButtonGray";

const ModalRate = forwardRef((props: any, ref) => {
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  return (
    <IonModal isOpen={props.visible} cssClass="modal-custom ">
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8 flex flex-col justify-between items-center"
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}
        >
          <div className="midle-circle " />
          <span className=" my-4 font-bold text-3xl z-0 text-black ">
            ¿Qué tal tu servicio?
          </span>
          <p className="z-0 text-lg text-black text-center leading-4 font-normal mx-20 my-4">
            Califica la experiencia que tuviste y cuentanos cómo te fue durante
            el servicio.
          </p>

          <div className="flex flex-col justify-between mx-6 items-center bg-white shadow-md mb-12 mt-2 rounded-xl z-0 h-auto w-auto p-10 ">
            <div className="flex flex-row justify-between ">
              <img
                className="rounded-lg w-1/4 h-32 object-cover "
                src={image1}
              />
              <div className="flex-col flex justify-self-start mx-5">
                <span className=" my-3 font-bold text-lg text-left">
                  {props.item?.content_service}
                </span>
                <p className=" text-lg text-gray-700 text-left leading-4 font-normal">
                  Con: Domingo gomez
                </p>
              </div>
            </div>
            <div className="flex w-full flex-row flex-wrap justify-between my-3">
              <div className="mr-1 my-3 w-48 ">
                <GrayButton redirect={null} text={"Estoy inconforme"} />
              </div>
              <div className="w-1/2 my-3">
                <GrayButton redirect={null} text={"Estuvo regular"} />
              </div>
              <div className="mr-1 w-48 ">
                <GrayButton redirect={null} text={"Buen servicio"} />
              </div>
              <div className="w-1/2 ">
                <GrayButton redirect={null} text={"Excelente servicio"} />
              </div>
            </div>
            <div className="flex flex-col flex-1 justify-between border-gray-300 rounded-lg h-64 w-full border-2 p-3 my-3" >
            <label className="ml-3 text-left text-base text-gray-400">¿Tienes algo que agregar?</label>
          
          <textarea placeholder="Ingresa tus notas" className="border-transparent w-auto text-left text-gray-300 text-base  p-3 h-12 scrolling-auto"/>
          </div>
          </div>
          <Button
            onClick={() => props.setVisible(false)}
            className="w-full h-16  bg-gradient-to-r from-yellow_1000 to-yellow_1200 rounded-md text-white shadow-sm font-bold mb-16"
          >
            Entendido
          </Button>
    
        </div>
      </div>
    </IonModal>
  );
});

export default ModalRate;
