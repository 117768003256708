import { IonModal } from "@ionic/react";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./ModalStyles.css";
import check from "../../img/Icons/check.svg";

import { Button } from "antd";
import InputComponent from "../Input/InputComponent";
const ModalFormDirection = forwardRef((props: any, ref) => {
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  return (
    <IonModal isOpen={props.visible} cssClass="modal-custom ">
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8 flex flex-col justify-between "
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}>
          <div className="flex flex-row w-full justify-between">
            <label className="text-3xl text-black  flex-1">
              Editar dirección
            </label>
            <label
              className="text-3xl text-black font-medium  mr-5"
              onClick={() => props.setVisible(false)}>
              x
            </label>
          </div>

          <InputComponent placeholder="Ej. Mi dirección" name="nameAddress" type="text" label="Nombre de la dirección" className="w-full my-5" labelColor="text-yellow_1000" icon=""/>
          <InputComponent placeholder="Ej. clle 5 #4-3" name="address" type="text" label="Dirección" className="w-full my-5" labelColor="text-yellow_1000" icon=""/>
          <InputComponent placeholder="Ej. Frente a la plaza San Francisco" name="addressNotes" type="text" label="Información adicional del domicilio" className="w-full my-5" labelColor="text-yellow_1000" icon=""/>
          <div className="flex flex-row ml-5 my-5">
            <input
              type="checkbox"
              id="direction"
              name="direction"
              value="direction"
              className="focus:outline-none"
            />
            <label htmlFor="direction">Dirección predeterminada</label>
          </div>
          <Button
            onClick={() => props.setVisible(false)}
            className="w-full h-16  bg-green_1000 rounded-md text-white shadow-sm font-bold mb-16"
          >
            Guardar mi dirección
          </Button>
        </div>
      </div>
    </IonModal>
  );
});

export default ModalFormDirection;
