import React from "react";
import {
  faCalendar,
  faMapMarker,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { IonCard, IonCardContent } from "@ionic/react";
import CardItem from "./CardItem";

interface Props {
  services: any;
}

const formatNumber=(number)=>{
  return new Intl.NumberFormat().format(number)
}

const Service = ({ services }: Props) => {
  return (
    <IonCard className="mx-5 h-auto flex-col	shadow-md flex rounded-xl  bg-white">
      <IonCardContent className="flex-row flex flex-1 border-b-2 p-3 items-center ">
        <img
          className="flex-none rounded-lg w-20 h-20 mr-3"
          src="https://cdn.pixabay.com/photo/2017/10/05/21/10/tool-2820950__340.jpg"
        />
        <div className="custom-col flex-grow h-auto ">
          <span className="text-2xl text-black font-semibold">
          {services?.content_service}
          </span>
          <p className="text-base text-gray-600 ">${formatNumber(services?.final_price)}</p>
        </div>
      </IonCardContent>
      <CardItem
        title="Programada para"
        icon={faCalendar}
        data={services?.notes}
      />
      <div className="divider" />
      <CardItem
        title="Dirección del servicio"
        icon={faMapMarker}
        data={services?.address?.description}
      />
      <div className="divider" />
      <CardItem
        title="Método de pago"
        icon={faClock}
        data={services?.payu_status}
      />
    </IonCard>
  );
};

export default Service;
