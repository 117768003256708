import Tv from '../../img/backgrounds/installationTv.png';
import Electric from '../../img/backgrounds/electric.png'


export const dummy = {
  services: [
    {
      id: "1",
      time: "En menos de 90 segundos",
      comment: "El técnico va en camino",
      state: "ACTIVE",
      request: [
        {
          name: "Cambio y montaje de interruptores de luz",
          price: "$50.000",
          img:Electric
        },
        {
            name: "Cambio y montaje de toma corrientes",
            price: "$50.000",
            img:Electric
          },
      ],
      messageCount:0
    },
    {
      id: "2",
      request: [
        {
          name: "Instalación de soporte de TV",
          price: "$50.000",
          img:Tv
        },
      ],
      time: "14/ Nov /2020,9 am",
      comment: "Solicitado y agendado",
      state: "ACTIVE",
      messageCount:1
    },
    {
        id: "3",
        request: [
          {
            name: "Cambio y montaje de interruptores de luz",
            price: "$50.000",
            img:Electric
          },
          {
              name: "Cambio y montaje de toma corrientes",
              price: "$50.000",
              img:Tv
            },
        ],
        time: "En menos de 90 segundos",
        comment: "El técnico va en camino",
        state: "SUCCES",
        messageCount:2
      },
      {
        id: "4",
        request: [
          {
            name: "Instalación de soporte de TV",
            price: "$50.000",
            img:Tv
          },
        ],
        time: "14/ Nov /2020,9 am",
        comment: "Solicitado y agendado",
        state: "SUCCES",
        messageCount:1
      },
  ],
  messages:[
    { 
      message:'Hola me dice el portero que no funciona el citofono. !Ta llegue¡',
      type:1
    },
    { 
      message:'De una! ya bajo entonces...',
      type:2
    }
  ],
  councils:[
    {
      id: 1,
      council: "Manten un trato respetuoso con el técnico que te hemos asignado."
    },
    {
      id: 2,
      council: "No hagas ni respondas preguntas personales durante el servicio."
    },
    {
      id: 3,
      council: "No aceptes servicios fuera de la App. No te cubrirá la Póliza ni la Garantía."
    },
    {
      id: 4,
      council: "Si pagas en efectivo asegurate de tener el dinero en el momento."
    },
    {
      id: 5,
      council: "Evita mal entendidos,cuida tus pertencias y supervisa al técnico"
    }
  ],
  service:[
    {
      id: 1,
      name: "Visita diagnostica servicios de Electricidad",
      price: "$35.000",
      address: "Kr 72 #48-12",
      paymetType: "Tarjeta de crédito",
      time: "En menos de 90 segundos",
      image: "https://cdn.pixabay.com/photo/2017/10/05/21/10/tool-2820950__340.jpg",
      technical: {
        id: 1,
        name: "Domingo Gómez",
        function: "Tecnico asignado",
        imageProfile: "https://cdn.pixabay.com/photo/2018/03/31/21/18/african-american-3279360_960_720.jpg"
      },
      requestAditional: [
        {
          id: 1,
          name: "Cambio de interruptores",
          price: "$64.200",
          quantity: 2
        },
        {
          id: 2,
          name: "Cambio de toma de corriente",
          price: "$34.000",
          quantity: 1
        }
      ]
    }

  ]
};
