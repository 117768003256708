import React from "react";


type InputProps = {
  name?: string;
  label: string;
  labelColor?: string;
  placeholder: string;
  icon: string;
  className: string;
  type?: string;
  onChange?: any;
  value?: any;
  onFocus?: any;
  error?: any;
  errorMsg?: string;
  onRef?: any;
  select?: boolean;
  options?: any;
  new?: any;
  optionsName?: any;
  aux?: any;
  setAux?: any;
};

const InputComponent: React.FC<InputProps> = (props) => {


  const handleSelect=(e)=>{
    if (props.aux===true) {
      
    }else{
      console.log('nothing')
    }
  }

  var Data = props.options,
    MakeItem = function (X) {
      return (
        <>
          <option value={X.id} key={X.id}>{X?.name}</option>,
          {props.new ? <option key={1}>Agregar una dirección</option> : null}
        </>
      );
    };
  return (
    <>
      <div
        className={
          `flex border bg-white rounded-lg px-4 pt-3 ${props.className} ` +
          (props.error && " border-red-500")
        }
      >
        <div className="w-11/12 pr-4">
          <p
            className={`text-base  font-bold mb-0 text-gray-500 ${props.labelColor} `}
          >
            {props.label}
          </p>
          {props.select ? ( 
            <select
              name={props.name}
              className="w-full mt-5 py-2 focus:outline-none bg-white"
              placeholder={props.placeholder}
              onFocus={props?.onFocus}
              ref={props.onRef}
              onChange={  (e) => {
                e.target.value === "Agregar una dirección" ?
                  props.setAux(true):props.setAux(false)
              }}
            >
              {Data.map(MakeItem)}
            </select>
          ) : props.onChange !== undefined ? (
            <input
              name={props.name}
              type={props.type}
              className="w-full py-2 focus:outline-none bg-white"
              placeholder={props.placeholder}
              onChange={
                props.type === "tel"
                  ? (e) => props.onChange(e)
                  : (e) => props.onChange(e.target.value)
              }
              value={props.value}
              onFocus={props?.onFocus}
              ref={props.onRef}
            />
          ) : (
            <input
              name={props.name}
              type={props.type}
              className="w-full py-2 focus:outline-none bg-white"
              placeholder={props.placeholder}
              onFocus={props?.onFocus}
              ref={props.onRef}
            />
          )}
        </div>
        <div className="w-1/12 pt-6">
          {props.icon !== "none" && (
            <i className={`${props.icon} text-gray-500`}></i>
          )}
        </div>
      </div>
      {props.error && (
        <span className="text-red-500 text-base">{props.errorMsg}</span>
      )}
    </>
  );
};

export default InputComponent;
