import { IonContent, IonPage, IonToast } from "@ionic/react";
import React, { useState, useEffect } from "react";
import NavbarSelect from "../../components/Navbar/NavbarSelect";
import Tags from "../../components/Tags/TagsComponent";
import Diagnostic from "../../components/Diagnostic/DiagnosticComponent";
import { Link, useParams } from "react-router-dom";
import GridService from "../../components/Grids/GridServiceComponent";
import { selectCategory } from "../../store/actions/informationActions";
import { useSelector, useDispatch } from "react-redux";
import BottomCart from "../../components/Panels/BottomCartComponent";

const Category: React.FC = () => {
  let category = useParams();
  const st_listCategories: any = useSelector(
    (state) => state.information.listCategories
  );
  const st_listSubcategories: any = useSelector(
    (state) => state.information.listSubcategories
  );
  const [categorySelected, setCategorySelected] = useState();
  const dispatch = useDispatch();
  const dispatcher = {
    setCategory: (index) => dispatch(selectCategory(index)),
  };

  const changeCategory = (index) => {
    dispatcher.setCategory(index);
    setCategorySelected(index);
  };

  useEffect(() => {
    if (st_listSubcategories.length === 0) {
      dispatcher.setCategory(category.identifier);
    }
  });
  return (
    <>
      <IonPage>
        <IonContent>
          <NavbarSelect
            select={st_listCategories}
            onChange={(value) => changeCategory(value)}
            selected={categorySelected}
          />
          <div className="py-20 min-h-screen bg-gray-100">
            <div className="mt-4 hidden">
              <Tags
                tags={[
                  { name: "Interruptores de luz" },
                  { name: "Tomacorrientes" },
                  { name: "Fusibles" },
                  { name: "Lamparas" },
                ]}
              />
            </div>
            <div className="px-6">
              <h3 className="font-bold text-4xl">Visita diagnostica</h3>
              <div className="mt-6">
                <Diagnostic select={st_listCategories} />
              </div>
              <div className="my-6">
                <Link to="/no-encuentro">
                  <button
                    className={`block w-full rounded-lg degrade-amarillo text-white font-bold text-2xl py-4 h-full`}
                  >
                    No encuentro mi servicio
                  </button>
                </Link>
              </div>
              <div>
                {st_listSubcategories.map((index, id) => {
                  return (
                    <GridService
                      key={id}
                      subcategoryName={index.name}
                      services={index.services}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <BottomCart />
      <IonToast
        isOpen={true}
        duration={4000}
        position="middle"
        message={
          "Recuerda que a partir del segundo servicio tienes un 25% de descuento."
        }
      />
    </>
  );
};

export default Category;
