const initialState = {
    listProductCategories: [],
    listProductSubcategories: [],
    listProducts: [],
    productCategoryName: "",
    productSubcategoryName: "",
    productCategoryImage: "",
    schedule: null,
    scheduleTemp: null,
    loading: false,
    error: false,
    success:false,
    message:"",
    newUser:null,
    product: {
        name: "",
        picture: "",
        short_description: "",
        long_description: "",
        price_base: "",
        price_discount: "",
        reference: "",
        weight: "",
        height: "",
        taxes: "",
        files: [],
        subcategory_id: "",
        services: [],
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // Esta se usa
        case "SET_PRODUCT_CATEGORIES":
            return {
                ...state,
                listProductCategories: action.payload,
            };
        case "SET_PRODUCT_SUBCATEGORIES":
            return {
                ...state,
                listProductSubcategories: action.payload,
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
            case "SET_NEW_USER":
                return {
                    ...state,
                    newUser: action.payload,
                };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload.error,
                message:action.payload.message                
            };
            case "SET_SUCCESS":
                return {
                    ...state,
                    success: action.payload,
                };
        case "SET_PRODUCTS":
            return {
                ...state,
                listProducts: action.payload,
            };
            // Esta se usa
        case "SET_PRODUCT_CATEGORY_NAME":
            return {
                ...state,
                listProductSubcategories: state.listProductCategories.find(
                    (x) => x.id == action.payload
                ).subcategories,
                productCategoryName: state.listProductCategories.find(
                    (x) => x.id == action.payload
                ).name,
                productCategoryImage: state.listProductCategories.find(
                    (x) => x.id == action.payload
                ).image,
            };
        case "SET_PRODUCT_SUBCATEGORY_NAME":
            return {
                ...state,
                listProducts: state.listProductSubcategories.find(
                    (x) => x.id == action.payload
                ).products,
                productSubCategoryName: state.listProductSubcategories.find(
                    (x) => x.id == action.payload
                ).name,
            };
        case "SET_PRODUCT":
            return {
                ...state,
                product: action.payload,
            };

        case "SET_PRODUCT_CATEGORY_BY_PRODUCT":
            let category = state.listProductCategories.find((x) =>
                x.subcategories.find((y) =>
                    y.products.find((z) => z.id == action.payload)
                )
            );
            let listProductSubcategories = state.listProductCategories.find((x) =>
                x.subcategories.find((y) =>
                    y.products.find((z) => z.id == action.payload)
                )
            ).subcategories;

            let listProducts = listProductSubcategories.find((y) =>
                y.products.find((z) => z.id == action.payload)
            ).products;

            let product = listProducts.find((y) => y.id == action.payload);

            return {
                ...state,
                listProductSubcategories: listProductSubcategories,
                listProducts: listProducts,
                product: product,
                productCategoryName: category.name,
            };
        case "SET_SCHEDULE":
            return {
                ...state,
                schedule: action.payload,
            };

        default:
            break;
    }
    return state;
};

export default reducer;