import { IonModal } from "@ionic/react";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./ModalStyles.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import completed from "../../img/Icons/Complete.svg";
import failed from "../../img/Icons/Cerrar.svg";
import { deleteCart } from "../../store/actions/cartActions";
import { setSuccess,setError } from "../../store/actions/productsActions";


import Slider from "../Slider/index";

import { Button } from "antd";

const ModalCreatedService = forwardRef((props: any, ref) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleSuccess = () => {
    props.setVisible(false);
    props.setVisibleModal(false)
    dispatch(deleteCart())
    dispatch(setSuccess(false))
    history.push("/historial");
   };

 const handleError = () => {
  props.setVisible(false);
  props.setVisibleModal(false)
  dispatch(setError({error:false,message:""}))
  dispatch(setSuccess(false))
  };

  useImperativeHandle(ref, () => ({
    showPopUp() {
      setShowModal(true);
    },
    hidePopUp() {
      setShowModal(false);
    },
  }));

  return (
       <IonModal  isOpen={props.visible} cssClass="modal-custom  ">
      <div  style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div
          className="bg-white w-100 px-6 py-8 flex flex-col justify-between items-center"
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
          }}
        >
          {props.visibleError === true ? (
            <>
               <img
                alt="completed"
                src={failed}
                className="text-center h-16 my-6"
              />
              <span className=" my-4  mx-16 font-bold text-3xl text-center text-black  ">
                !Parece que tenemos un error!
              </span>
              <p className="text-xl text-gray-600 text-center font-normal mx-10 my-4 leading-6">
                {props.message? props.message:'Disculpa las molestías,estamos trabajando para brindarte un mejor servicio'}
              </p>
              <Button
            onClick={() => handleError()}
            className="w-full h-20  bg-gradient-to-r from-yellow_1000 to-yellow_1200 rounded-lg text-white shadow-sm font-bold"
          >
            Entendido
          </Button>
            </>
          ) : (
            <>
              <img
                alt="completed"
                src={completed}
                className="text-center h-16 my-6"
              />
              <span className=" my-4  mx-16 font-bold text-3xl text-center text-black  ">
                !Tu servicio se creo exitosamente!
              </span>
              <p className="text-xl text-gray-600 text-center font-normal mx-10 my-4 leading-6">
                Te avisaremos cuando un técnico tome el servicio y se encuentre
                en camino a tu dirección.
              </p>
              <Slider
                popup={true}
                councils={props.councils}
                className={"h-64"}
              />
                 <Button
            onClick={() => handleSuccess()}
            className="w-full h-20  bg-gradient-to-r from-yellow_1000 to-yellow_1200 rounded-lg text-white shadow-sm font-bold"
          >
            Entendido
          </Button>
            </>
          )}
       
        </div>
      </div>
    </IonModal>
   );
});

export default ModalCreatedService;
