import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BottomCart: React.FC = () => {
  const st_currentServices: any = useSelector(
    (state) => state.cart.currentServices
  );
  const st_currentProducts: any = useSelector(
    (state) => state.cart.currentProducts
  );
  const [state_total, setTotal] = useState(0);
  const [state_quantity, setQuantity] = useState(0);
  const [state_discount, setDiscount] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    let total = 0;
    let max = 0;
    let quantity = 0;
    st_currentServices.map((item) => {
      quantity = quantity + item.quantity;
      if (item.quantity > 1 && !state_discount) {
        setDiscount(true);
      }
      if (max < item.base_cost) {
        max = item.base_cost;
      }
      total = total + item.base_cost * item.quantity;
    });

    if (st_currentServices.length > 1 || state_discount) {
      total = (total - max) * 0.75 + max;
      if (!state_discount) {
        setDiscount(true);
      }
    }

    let productTotal = 0;
    if (st_currentProducts.length > 0) {
      st_currentProducts.map((item) => {
        quantity = quantity + item.quantity;
        productTotal = productTotal + item.price_base * item.quantity;
      });
    }
    if (state_total !== total + productTotal) {
      setTotal(total + productTotal);
    }
    if (state_quantity !== quantity) {
      setQuantity(quantity);
    }
  });

  // // Total of products

  return state_total !== 0 ? (
    <Link to="/carrito">
      <div
        className="absolute w-full px-4 py-3"
        style={{ zIndex: 200, bottom: "0px" }}
      >
        <div className="flex w-full boton-whapp rounded-lg py-5 px-6">
          <div className="w-1/2 text-white text-3xl font-bold">
            <span className="px-5 mr-4 py-2 border border-white rounded-lg">
              {state_quantity}
            </span>
            {formatter.format(state_total)}
          </div>
          <div className="w-1/2 text-white font-bold text-right pr-3 text-3xl">
            Continuar <i className="fa fa-arrow-right"></i>
          </div>
        </div>
      </div>
    </Link>
  ) : null;
};

export default BottomCart;
