import React from "react";
import diagnostico from "../../img/visitadiagnostica.png";

const Diagnostic: React.FC<any> = ({ select }) => {
  return (
    <div className="border rounded-lg p-5 bg-white shadow-lg">
      <div className="flex">
        <div className="w-3/12">
          <img className="rounded-lg" src={diagnostico} alt="" />
        </div>
        <div className="w-9/12 pl-4">
          <h4 className="font-semibold text-3xl">Servicio diagnostico</h4>
          <p className="text-gray-500">$50.000</p>
        </div>
      </div>
      <div>
        <select
          name="category"
          className="w-full border-yellow-500 my-3"
          id=""
          defaultValue={""}
        >
          <option disabled value="">
            Selecciona la categoría
          </option>
          {select.map((index, id) => {
            return (
              <option value={index.id} key={id}>
                {index.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="border-t-2 mt-3 mb-6"></div>
      <p className="text-gray-500">
        Técnico certificado. <br />
        Posibilidad de resolver un problema.
      </p>
    </div>
  );
};

export default Diagnostic;
