import React from "react";
import ProductIndividual from "../Individuals/ProductIndividualComponent";

const GridProduct: React.FC<any> = ({ subcategoryName, products }) => {
  if (products.length !== 0) {
    return (
      <div>
        <h3 className="font-bold my-6">
          {subcategoryName === "UNICA" ? "Productos" : subcategoryName}
        </h3>
        <div>
          {products.map((index, id) => {
            return <ProductIndividual notAdd={false} product={index} key={id} />;
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default GridProduct;
